import React, { useState, useEffect } from "react";
import { UserTables as UserData } from "./components";
// import { Dropdown, InputGroup, Input, Icon, Button } from 'rsuite';
import "rsuite/dist/styles/rsuite-default.css";
import { Col, Button } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import * as API from "../../../ApiUrl";
import AddSystemUserModal from "../AddSystemUserModal";
import add from "../../../assets/img/icons/add.svg";

import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import searchIcon from "../../../assets/img/icons/searchIcon.svg";

import { Form, InputGroup } from "@themesberg/react-bootstrap";

import dropDown from "../../../assets/img/icons/dropDown.svg";

const SystemUser = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [userStores, setUserStores] = useState([]);
  const [userroletitle, setuserroletitle] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userroleOption, setUserroleOption] = useState([
    { name: "Select User Role", value: "" },
  ]);
  const [pageChange, setPageChange] = useState({
    search: "",
    userrole: "",
  });

  const history = useHistory();

  const userRole = useSelector((state) => state.authReducer.userRole);

  useEffect(() => {
    if (userRole === "cashier") history.push("/");
  });

  const toggleAddUserModal = () => {
    setShowAddUserModal((prevState) => !prevState);
  };

  const getUsers = async () => {
    setFetchUsersLoading(true);
    try {
      let token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API.USER_LIST}`, config);

      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setFetchUsersLoading(false);
    }
  };

  const getAllStores = async () => {
    try {
      let token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API.STORE_LIST}?limit=100`, config);
      if (response?.data?.results) {
        setUserStores(response.data.results);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserLevelOptions();
    getAllStores();
  }, []);

  const getUserLevelOptions = async () => {
    try {
      let token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API.USER_ROLE}`, config);
      setUserroleOption([...userroleOption, ...response.data.data]);
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handlefilter = (e) => {
    let l = userroleOption.filter((a) => a.name === e);
    setuserroletitle(l[0].name);

    setPageChange({
      ...pageChange,
      currentPage: 0,
      userrole: e === "Select User Role" ? "" : e,
    });
  };

  const handleSearch = (e) => {
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="user stb-h-100">
        <div className="users stb-h-100 bg-white p-4 radi">
          <div className="page-header  pb-4 space-between">
            <div>
              <h3 className="m-0 page-title">System User</h3>
              <p>View your users</p>
            </div>
            <div className="gap-4 tools">
              <div className="filter">
                <Form
                  onSubmit={(e) => e.preventDefault()}
                  className="w-100 search"
                >
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Text>
                        <img src={searchIcon} alt="search" />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>

              <div className="filter">
                <Dropdown as={ButtonGroup} className="w-100">
                  <Dropdown.Toggle id="dropdown-basic">
                    {userroletitle || "Select User Role"}
                    <img
                      src={dropDown}
                      className="dropdown-icon"
                      style={{ marginLeft: "4px" }}
                      alt="drop-down"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    {userroleOption.map((a, i) => (
                      <Dropdown.Item
                        as="button"
                        value={a.name}
                        onClick={() => {
                          handlefilter(a.name);
                        }}
                        key={i + "res"}
                      >
                        {a.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{" "}
              </div>

              <div className="filter">
                <Button
                  onClick={() => {
                    if (userStores.length > 0) toggleAddUserModal();
                    else toast.error("Please add a store first");
                  }}
                  style={{ minHeight: "40px" }}
                  appearance="default"
                  className="btn load text-white d-flex flex-row gap-2 "
                >
                  Add New User
                </Button>
              </div>
            </div>
          </div>

          <UserData
            fetchUsersLoading={fetchUsersLoading}
            users={users}
            userStores={userStores}
            pageChange={pageChange}
          />
          {showAddUserModal && (
            <AddSystemUserModal
              onClose={toggleAddUserModal}
              isOpen={showAddUserModal}
              // This is to trigger re-render after adding user to fetch new list
              onUserAdd={() => {
                getUsers();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemUser;
