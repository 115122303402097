import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { CgHome } from "react-icons/cg";
import { connect } from "react-redux";

import stblogo from "../assets/img/icons/stblogo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import HomeIcon from "../assets/img/sideicon/home.svg";
import AdminIcon from "../assets/img/sideicon/adminstration.svg";
import TransIcon from "../assets/img/sideicon/transaction.svg";
import MerchantIcon from "../assets/img/sideicon/merchant.svg";
import CustomerIcon from "../assets/img/sideicon/customer.svg";
import InvoiceIcon from "../assets/img/sideicon/invoice.svg";
import SupportIcon from "../assets/img/sideicon/support.svg";
import SettingIcon from "../assets/img/sideicon/setting.svg";

import { Routes } from "../routes";
import { getStatus } from "../redux/actions/kybActions";

const Sidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const curRole = props.userRole;

  const onCollapse = () => setShow(!show);
  const history = useHistory();

  useEffect(() => {
    props.getStatus();
  }, []);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, image, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between border-none box-shad-none align-items-center"
          >
            <span>
              {image ? (
                <Image
                  src={image}
                  width={20}
                  height={20}
                  className="sidebar-icon svg-icon"
                />
              ) : null}
              <span className="sidebar-text light_color colr font-fam">
                {title}
              </span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level pt-0">
            <Nav className="flex-column bgchild  ">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start   align-items-center justify-content-between "
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <CgHome size="1em" />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text light_color font-fam">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const showWithdrawAndWallet = !(
    props.userRole === "cashier" ||
    (props.userRole === "administrator" && !props.isSingleWallet)
  );

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          {/* <Image src={ReactHero} className="navbar-brand-light" /> */}
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block primary_color text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                {/* <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Merchant 1</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{' '}
                    Sign Out
                  </Button>
                </div> */}
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0 ">
              <img className="mb-3" src={stblogo} alt="" />

              <NavItem
                title="Home"
                link={Routes.DashboardOverview.path}
                image={HomeIcon}
              />

              {props.userRole !== "cashier" && (
                <CollapsableNavItem
                  eventKey="administration/"
                  title="Administration"
                  image={AdminIcon}
                >
                  {/* {props.userRole === 'administrator' ? ( */}
                  <>
                    {props.userRole !== "cashier" ? (
                      <NavItem
                        title="System User"
                        link={Routes.SystemUser.path}
                      />
                    ) : null}

                    {props.userRole === "administrator" ||
                    props.userRole === "franchisee" ? (
                      <NavItem
                        title="Business Details"
                        link={Routes.BusinessDetails.path}
                      />
                    ) : null}

                    {props.userRole !== "cashier" ? (
                      <NavItem title="Store" link={Routes.StoreMenu.path} />
                    ) : null}
                  </>
                  {/* ) : (
                  <NavItem
                    title="Business Details"
                    link={Routes.BusinessDetails.path}
                    />
                  )} */}
                </CollapsableNavItem>
              )}
              <CollapsableNavItem
                eventKey="reports/"
                title="Reports"
                image={TransIcon}
              >
                <NavItem title="Transactions" link={Routes.Transaction.path} />
              </CollapsableNavItem>

              {!(
                props.userRole === "administrator" && !props.isSingleWallet
              ) ? (
                <NavItem
                  title="Transactions"
                  link={Routes.ReportTransactions.path}
                  image={TransIcon}
                />
              ) : null}

              {!(
                (props.userRole === "administrator" && !props.isSingleWallet) ||
                props.userRole === "cashier" ||
                (props.userRole === "manager" && props.isSingleWallet)
              ) ? (
                <CollapsableNavItem
                  eventKey="merchantprofile/"
                  title="Merchant Profile"
                  image={MerchantIcon}
                >
                  {props.userRole !== "manager" ? (
                    <NavItem title="Wallets" link={Routes.Menu.path} />
                  ) : null}
                  {/* <NavItem title="Statement" link={Routes.Statement.path} /> */}

                  <NavItem title="Withdrawal" link={Routes.Withdrawl.path} />
                </CollapsableNavItem>
              ) : null}
              <Dropdown.Divider className="my-3 border-indigo" />
              <NavItem
                title="Support"
                image={SupportIcon}
                link={Routes.UserChatGeneral.path}
              />
              <NavItem
                title="Settings"
                image={SettingIcon}
                link={Routes.Settings.path}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

function mapStateToProps({ kybReducer, authReducer }) {
  const { status } = kybReducer;
  const { userRole, isSingleWallet } = authReducer;
  return {
    data: status.data,
    userRole,
    isSingleWallet,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(Sidebar);
