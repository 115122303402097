import PropTypes from "prop-types";
import React, { useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Avatar,
  Typography,
  AvatarGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
// utils
import Iconify from "../../../components/Iconify";
import { useDispatch } from "react-redux";
import { toggleStatus } from "../../../redux/actions/chat";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { capitalize } from "lodash";
// import ConfirmModal from "../../../components/confirmModal/ConfirmModal";
import createAvatar from "../../../utils/createAvatar";
import ButtonPrimary from "../../../components/ButtonPrimary";
import ConfirmModal from "../../../components/confirmModal/ConfirmModal";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  flexShrink: 0,
  minHeight: 92,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

export default function ChatHeaderDetail({
  conversation,
  openOnly,
  offset,
  limit,
  setOffset,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleConfirm = () => {
    setOpenConfirm((prev) => !prev);
  };
  const { status } = conversation;
  const updateStatus = async () => {
    try {
      dispatch(
        toggleStatus(
          status === "open" ? "closed" : "open",
          conversation.id,
          offset
        )
      );
      toast.success("Status Updated Successfully");
      toggleConfirm();
      // if (openOnly) {
      //   history.push(Routes.UserChatGeneral.path);
      // }
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error?.message ?? 'Status update failed!');
    }
  };

  return (
    <RootStyle>
      <OneAvatar user={conversation.user} subject={conversation.subject} />

      <Box sx={{ flexGrow: 1 }} />

      <Tooltip title={"Go to KYC"} sx={{ mr: 2 }}>
        <IconButton
          onClick={() => {
            history.push({
              pathname: Routes.UserKyc.path,
              state: {
                openKycModal: true,
                userId: conversation?.user?.data?.id,
              },
            });
          }}
        >
          <Iconify
            icon="material-symbols:open-in-new-rounded"
            width={20}
            height={20}
          />
        </IconButton>
      </Tooltip>
      <ButtonPrimary onClick={toggleConfirm}>
        {status === "open" ? "Close Ticket" : "Reopen Ticket"}
      </ButtonPrimary>

      <ConfirmModal
        open={openConfirm}
        onClose={toggleConfirm}
        title={status === "open" ? "Close" : "Reopen"}
        content={
          <>
            Are you sure want to{" "}
            <strong> {status === "open" ? "close" : "reopen"} </strong> the
            ticket?
          </>
        }
        action={
          <ButtonPrimary onClick={updateStatus}>
            {status === "open" ? "Close" : "Reopen"}
          </ButtonPrimary>
        }
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function OneAvatar({ user, subject }) {
  // const { first_name, last_name } = user?.data || {};
  const name = `${user?.name}`;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <Avatar alt={name} sx={{ mr: 1, bgcolor: createAvatar(name).color }}>
          {createAvatar(name).name}
        </Avatar>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{capitalize(name)}</Typography>
        <Typography variant="body2" color={"textSecondary"}>
          {subject}
        </Typography>
      </Box>
    </Box>
  );
}

// ----------------------------------------------------------------------
