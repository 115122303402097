import React, { useState } from "react";
import { Button } from "rsuite";
import { Col, Row } from "@themesberg/react-bootstrap";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { textFieldLabelProps } from "../../../utils/common";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function WithdrawPasswordDialog({
  open,
  onClose: handlePasswordClose,
  title,
  handlePasswordSubmit,
  loading = false,
  error = {},
}) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = async (values) => {
    const value = {
      ...values,
    };

    console.log(password);
    handlePasswordSubmit(password);
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handlePasswordClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth
      disableEscapeKeyDown={true}
      onClose={handleCloseModal}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "480px",
          minHeight: "216px",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title"> {title}</p>
      </DialogTitle>
      <hr className="hr" />

      <DialogContent className="mt-3">
        <form onSubmit={() => {}}>
          <Row>
            <Col xl={12} xs={12}>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className="w-100 pb-0 mb-0"
              >
                <InputLabel
                  style={textFieldLabelProps.style}
                  htmlFor="password"
                >
                  Enter Password
                </InputLabel>
                <OutlinedInput
                  className="w-100"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  label="Enter Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Col>
          </Row>
        </form>
      </DialogContent>
      <hr className="hr" />
      <DialogActions className="footer-content">
        <Button
          className="btn white-btn action-button basis-30"
          onClick={handlePasswordClose}
          appearance="subtle"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            loading || !password ? "disabled" : null
          }`}
          loading={loading}
          appearance="primary"
          onClick={onSubmit}
          disabled={loading || !password}
        >
          {loading ? `Please wait...` : `Next`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WithdrawPasswordDialog;
