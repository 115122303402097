import React, { useState, useEffect } from "react";
import { Button, Card } from "@themesberg/react-bootstrap";
import _ from "lodash";
import axios from "axios";
import { formatCurrency } from "../../../utils/common";

import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import * as API from "../../../ApiUrl";
import BTC from "../../../assets/img/icons/BTC.svg";
import XRP from "../../../assets/img/icons/XRP.svg";
import LTC from "../../../assets/img/icons/LTC.svg";
import ELS from "../../../assets/img/icons/ELS.png";
import AddCardIcon from "@mui/icons-material/AddCard";
import SelectDepositMethod from "../../../components/model/SelectDepositMethod";
import { toast } from "react-toastify";

const cellStyle = {
  color: "black",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const WalletTable = ({
  loading,
  walletList: propWalletList = { count: 0, results: [] },
  setpageChange,
  paginationdetails,
  handleCallBack,
  isSingleWallet,
}) => {
  const [isWalletTrustlineModel, setisWalletTrustlineModel] = useState(false);
  const [showSelectDepositMethod, setShowSelectDepositMethod] = useState(false);
  const [allCoins, setAllCoins] = useState([]);
  const [wallets, setWallets] = useState({});
  const [selectedCoin, setSelectedCoin] = useState({
    coin: "",
    address: "",
    store: "",
  });
  const [selectedWallet, setSelectedWallet] = useState(null);

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const getAllCoins = async () => {
    const response = await axios.get(`${API.GET_COINS}`, {
      params: {
        limit: 100,
      },
      ...config, // Spread the config object here
    });
    setAllCoins(response.data.results);
  };

  useEffect(() => {
    getAllCoins();
  }, []);

  // const getAllWallets = async () => {
  //   return await axios.get(
  //     `${API.GET_MERCHENT_WALLET_LIST}?limit=${propWalletList.count}`,
  //     config
  //   );
  // };

  // useEffect(() => {
  //   setReqLoading(true);
  //   if (propWalletList?.results?.length > 0) {
  //     try {
  //       getAllWallets().then((res) => {
  //         // console.log(res.data);
  //         console.log(res.data);
  //         setAllWallets(res.data);
  //       });
  //     } catch (err) {
  //       toast.error('Fetching wallets failed');
  //     }
  //   }
  //   setReqLoading(false);
  // }, [propWalletList]);

  useEffect(() => {
    console.log(propWalletList);
    if (propWalletList.results.length > 0) {
      let updatedWallets = propWalletList;

      let filteredArray = updatedWallets.results;
      console.log(filteredArray);

      if (paginationdetails.coin !== "") {
        filteredArray = filteredArray.filter(
          (wallet) => wallet?.coin?.id === paginationdetails.coin
        );
      }

      if (paginationdetails.store !== "") {
        filteredArray = filteredArray.filter(
          (wallet) => wallet?.store?.id === paginationdetails.store
        );
      }

      if (paginationdetails.search !== "") {
        filteredArray = filteredArray.filter(
          (wallet) =>
            wallet?.store?.name
              .toLowerCase()
              .includes(paginationdetails.search.toLowerCase()) ||
            wallet?.store?.store_id
              .toLowerCase()
              .includes(paginationdetails.search.toLowerCase()) ||
            wallet?.coin?.name
              .toLowerCase()
              .includes(paginationdetails.search.toLowerCase()) ||
            wallet?.coin?.symbol
              .toLowerCase()
              .includes(paginationdetails.search.toLowerCase()) ||
            wallet?.address
              .toLowerCase()
              .includes(paginationdetails.search.toLowerCase())
        );
      }

      if (filteredArray.length > 0) {
        updatedWallets = { ...propWalletList, results: filteredArray };
      }

      setWallets(updatedWallets);
      console.log(updatedWallets, "[][]][][][");
    }
  }, [paginationdetails, propWalletList.results]);

  const handleClick = (address, coin, store) => {
    const filterXrp = propWalletList.results.filter(
      (item) => item.coin.symbol === "XRP"
    );

    if (filterXrp.length === 1) {
      setSelectedCoin({
        ...selectedCoin,
        coin: coin,
        address: filterXrp[0].address,
        store: store,
      });
    } else {
      const finalData = filterXrp.filter(
        (item) => item.store.store_id === store.store_id
      );

      setSelectedCoin({
        ...selectedCoin,
        coin: coin,
        address: finalData[0].address,
        store: store,
      });
    }

    setisWalletTrustlineModel(true);
  };

  // Datagrid components

  const createDataRow = (wallet) =>
    isSingleWallet
      ? {
          id: wallet.id,
          coin: wallet.coin.symbol,
          // address: wallet.address,
          balance: formatCurrency(
            wallet.balance,
            wallet.coin.decimal_place,
            wallet.coin.symbol
          ),
          valueInCurrency: formatCurrency(
            wallet.balance_currency,
            wallet.currency.decimal_place,
            wallet.currency.symbol,
            wallet.currency.symbol_prefix
          ),
          coinData: wallet.coin,
          storeData: wallet?.store,
          logo: wallet.coin.logo,
          wallet,
        }
      : {
          id: wallet.id,
          coin: wallet.coin.symbol,
          store: _.startCase(_.toLower(wallet?.store?.name)),
          // address: wallet.address,
          balance: formatCurrency(
            wallet.balance,
            wallet.coin.decimal_place,
            wallet.coin.symbol
          ),
          valueInCurrency: formatCurrency(
            wallet.balance_currency,
            wallet.currency.decimal_place,
            wallet.currency.symbol,
            wallet.currency.symbol_prefix
          ),
          coinData: wallet.coin,
          storeData: wallet?.store,
          logo: wallet.coin.logo,
          wallet,
        };

  const rows =
    wallets?.results?.length > 0
      ? wallets.results
          .sort((a, b) => b.id - a.id)
          .map((user) => createDataRow(user))
      : [];

  const columns = isSingleWallet
    ? [
        {
          field: "id",
          headerName: "ID",
          flex: 1,
          minWidth: 100,
          // maxWidth: 120,
          headerClassName: "header",
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            return <strong>{params.formattedValue}</strong>;
          },
          // align: 'center',
        },
        {
          field: "coin ",
          headerName: "Coin",
          sortable: false,
          flex: 1,
          minWidth: 150,
          headerClassName: "header",
          renderCell: (params) => {
            return (
              <strong>
                <img
                  style={{ width: "40px", height: "40px", marginRight: "12px" }}
                  src={params.row.logo}
                  alt="coin"
                />
                {params.row.coin}
              </strong>
            );
          },
        },
        {
          field: "balance",
          headerName: "Balance",
          sortable: false,
          flex: 1,

          minWidth: 200,
          // maxWidth: 200,
          headerClassName: "header",
          // align: 'center',
        },
        {
          field: "valueInCurrency",
          headerName: "Value in Currency",
          sortable: false,
          flex: 1,

          minWidth: 150,
          // maxWidth: 200,
          headerClassName: "header",
          // align: 'center',
        },

        {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          minWidth: 100,
          // maxWidth: 140,
          headerClassName: "header",
          renderCell: (params) => {
            return (
              <Stack>
                <Tooltip title="Add Funds" arrow>
                  <span>
                    <IconButton
                      onClick={() => {
                        if (params?.row?.wallet?.coin?.is_fund_available) {
                          setShowSelectDepositMethod(true);
                          console.log("selected", params?.row?.wallet);
                          setSelectedWallet(params?.row?.wallet);
                        } else {
                          toast.error(
                            params?.row?.wallet?.coin?.fund_error_message ||
                              "Funding not allowed for this coin"
                          );
                        }
                        // setDeleteId(params.value.id);
                        // toggleShowDeleteModal();
                      }}
                    >
                      <AddCardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            );
          },
        },
      ]
    : [
        {
          field: "id",
          headerName: "ID",
          flex: 1,
          minWidth: 100,
          // maxWidth: 120,
          headerClassName: "header",
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            return <strong>{params.formattedValue}</strong>;
          },
          // align: 'center',
        },
        {
          field: "coin ",
          headerName: "Coin",
          sortable: false,
          flex: 1,
          minWidth: 150,
          // maxWidth: 200,
          headerClassName: "header",
          renderCell: (params) => {
            let coin =
              params.row.coin === "XRP"
                ? XRP
                : params.row.coin === "BTC"
                ? BTC
                : params.row.coin === "ELS"
                ? ELS
                : LTC;
            return (
              <strong>
                <img
                  style={{ width: "40px", height: "40px", marginRight: "12px" }}
                  src={coin}
                  alt="coin"
                />
                {params.row.coin}
              </strong>
            );
          },
          // align: 'center',
          // headerAlign: 'center',
        },
        {
          field: "store",
          headerName: "Store",
          flex: 1,

          minWidth: 200,
          // maxWidth: 300,
          headerClassName: "header",
          renderCell: (params) => {
            return <strong>{params.formattedValue}</strong>;
          },
          // align: 'center',
        },
        {
          field: "address",
          headerName: "Address",
          sortable: false,
          flex: 2,
          minWidth: 200,
          maxWidth: 3000,
          headerClassName: "header",
          renderCell: (params) => {
            return <p style={cellStyle}>{params.formattedValue}</p>;
          },
          // align: 'center',
        },

        {
          field: "balance",
          headerName: "Balance",
          sortable: false,
          flex: 1,

          minWidth: 200,
          // maxWidth: 200,
          headerClassName: "header",
          // align: 'center',
        },
        {
          field: "currencyValue",
          headerName: "Currency Value",
          sortable: false,
          flex: 1,

          minWidth: 150,
          // maxWidth: 200,
          headerClassName: "header",
          // align: 'center',
        },
        {
          field: "currency",
          headerName: "Currency",
          sortable: false,
          flex: 1,

          minWidth: 100,
          // maxWidth: 200,
          headerClassName: "header",
          // align: 'center',
        },
        {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          minWidth: 100,
          // maxWidth: 140,
          headerClassName: "header",
          renderCell: (params) => {
            return (
              <Stack>
                <Tooltip title="Add Funds" arrow>
                  <span>
                    <IconButton
                      onClick={() => {
                        setShowSelectDepositMethod(true);
                        // setDeleteId(params.value.id);
                        // toggleShowDeleteModal();
                      }}
                    >
                      <AddCardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            );
          },
        },
      ];

  return (
    <>
      <Card border="none" className="shadow-none">
        <Box
          sx={{
            height: 840,
            width: "100%",
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                // backgroundColor: 'rgba(235, 235, 235, .7)',
                fontWeight: 500,
                color: "#667085",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
              },
            },
            "& .header": {
              backgroundColor: "#F4F4F4",
              "& div": {
                "& div": {
                  "& div": {
                    "& div": {
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                    },
                  },
                },
              },
            },
          }}
        >
          <DataGrid
            sx={{
              borderRadius: "8px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
              width: "100%",
            }}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            rows={rows}
            columns={columns}
            rowHeight={72}
            pageSize={7}
            rowsPerPageOptions={[5]}
            disableColumnFilter={true}
            disableColumnMenu={true}
            loading={loading}
          />
        </Box>
        {wallets?.count === 0 && (
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            No Record Found
          </p>
        )}

        {showSelectDepositMethod && (
          <SelectDepositMethod
            selectedWallet={selectedWallet}
            handleCloseModal={() => setShowSelectDepositMethod(false)}
            isOpen={showSelectDepositMethod}
            onClose={() => setShowSelectDepositMethod(false)}
          />
        )}
      </Card>
    </>
  );
};

export default WalletTable;
