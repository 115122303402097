import React, { useState } from "react";
import { Col, Row, Card, ButtonToolbar } from "@themesberg/react-bootstrap";
import { Button } from "rsuite";

import Caution from "../../assets/img/caution.svg";

import profile from "../../assets/img/team/vanilla-profile.jpg";
import profile2 from "../../assets/img/team/vanilla-profile2.png";
import profile3 from "../../assets/img/team/vanilla-profile3.png";

import moment from "moment-timezone";

const ProfileCard = ({ userProfileData }) => {
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));

  return (
    <Card border="none" className="box-shadow-0 p-0 mt-3 pb-4 pb-xl-0">
      <Row>
        <Col xl={7}>
          <Card.Body className="pb-xl-5 pb-0">
            <Row className="align-items-center">
              <Col xl={5}>
                <Card.Img
                  src={profile3}
                  alt="Profile Name"
                  className="user-avatar large-avatar col-xl-6 rounded-circle mb-4"
                />
              </Col>
              <Col xl={7}>
                <Card.Title className="col-xl-12 font-50 font-40-xs f-900 font-fam mb-0">{`${userInfo.first_name} ${userInfo.last_name}`}</Card.Title>
                <Row className="align-items-center mb-4">
                  {/* <Card.Subtitle className="col-xl-5 font-fam font-12 font-700 light_color m-0">{userProfileData.dob? moment(userProfileData.dob).format('DD-MMM-YYYY') : ''}</Card.Subtitle> */}
                  <Card.Text className="font-fam col-xl-7 font-700 font-12 color-blue">
                    {userInfo.email}
                  </Card.Text>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Col>

        {/* <Col xl={4}>
          <Card.Body className="d-flex mt-xl-4 mt-0 align-items-start">
            <Card.Img
              src={Caution}
              alt="Caution Sign"
              className="w-50px me-2"
            />
            <Card.Text className="font-fam font-13">
              We recommend you to turn on 2FA for security reasons, use Google
              Authenticator app to scan code.
            </Card.Text>
          </Card.Body>
          <Button
            appearance="primary"
            className="centerbtn primary_color font-fam"
          >
            {' '}
            Enable 2FA Security
          </Button>
        </Col> */}
      </Row>
    </Card>
  );
};

export default ProfileCard;
