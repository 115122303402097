import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { capitalize, startCase } from "lodash";
import { DateRangePicker } from "rsuite";
import { toLower } from "lodash";
import { getBusinessDetails } from "../../redux/actions/businessApi";
import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import { Loader } from "rsuite";
import LinearProgress from "@mui/material/LinearProgress";

import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import MerchantWalletAddress from "../../components/model/MerchantWalletAddress";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";
import Dropdown from "react-bootstrap/Dropdown";
import dropDown from "../../assets/img/icons/dropDown.svg";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { formatCurrency } from "../../utils/common";
import greentick from "../../assets/img/icons/greentick.svg";
import cross from "../../assets/img/icons/cross.svg";
import warning from "../../assets/img/icons/warning.svg";
import { toast } from "react-toastify";
import { setSingleWallet } from "../../redux/actions/auth";
import AreaChartComp from "../../components/charts/AreaChartComp";
import DoughnutChartComp from "../../components/charts/DoughnutChartComp";
import CustomTooltip from "../../components/CustomTooltip";
import PaymentModal from "../../components/PaymentModal";
import MessageModal from "../../components/MessageModal";
import reload from "../../assets/img/icons/sync.svg";
import CreateWalletModal from "./CreateWalletModal";
import ShowWalletDetails from "./ShowWalletDetails";
import { useDispatch } from "react-redux";

const { afterToday } = DateRangePicker;
const timePeriodOptions = [
  {
    name: "Daily",
    value: "day",
  },
  {
    name: "Weekly",
    value: "week",
  },
  {
    name: "Monthly",
    value: "month",
  },
  {
    name: "Yearly",
    value: "year",
  },
];

const reportTimePeriods = [
  {
    name: "This Week",
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Month",
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Quarter",
    from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Year",
    from: format(startOfYear(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
];
// '#916c00',
const doughnutChartColors = ["#FEEBB0", "#FDD354", "#FCBD00", "#B38600"];

export default (props) => {
  const [isCreateWalletVisible, setIsCreateWalletVisible] = useState(false);
  const [isWalletDetailsVisible, setIsWalletDetailsVisible] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [isMerchantWalletAddressModel, setisMerchantWalletAddressModel] =
    useState(false);
  const [showRevenue, setShowRevenue] = useState(true);
  const [showReturn, setShowReturn] = useState(true);
  const [showWithdrawal, setShowWithdrawal] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("day");
  const [timePeriodTitle, setTimePeriodTitle] = useState("Daily");
  const [reportTimePeriod, setReportTimePeriod] = useState({
    from: reportTimePeriods[0].from,
    to: reportTimePeriods[0].to,
  });
  const [reportTimePeriodTitle, setReportTimePeriodTitle] =
    useState("This Week");
  const [currency, setCurrency] = useState({ code: "", symbol: "" });
  const [balance, setBalance] = useState(0);
  const [payoutsThisMonth, setPayoutsThisMonth] = useState(0);
  const [translist, setTranslist] = useState({ count: 0, results: [] });
  const [currentTransTime, setCurrentTransTime] = useState("--");
  const [prevTransTime, setPrevTransTime] = useState("--");
  const [tableLoading, setTableLoading] = useState(true);
  const [salesReport, setSalesReport] = useState({});
  const [groupByReceiptReport, setGroupByReceiptReport] = useState({});
  const [customerReport, setCustomerReport] = useState({});
  const [memberReport, setMemberReport] = useState({});
  const [userCurrency, setUserCurrency] = useState({
    symbol: "",
    decimalPlace: 2,
    symbolPrefix: false,
  });
  const [dataPointName, setDataPointName] = useState({
    current: "Today",
    currentReceipt: "0",
    previous: "Yesterday",
    previousReceipt: "0",
  });
  const [fetchChartDataLoading, setFetchChartDataLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState({
    revenue: false,
    receipt: false,
    customer: false,
    members: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [walletInitializationLoading, setWalletInitializationLoading] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [refreshReports, setRefreshReports] = useState(0);
  const [refreshAnalytics, setRefreshAnalytics] = useState(0);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [payoutLoading, setPayoutLoading] = useState(true);
  const [calenderValue, setCalenderValue] = useState([
    new Date(reportTimePeriods[0].from),
    new Date(reportTimePeriods[0].to),
  ]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.authReducer.userRole);
  const isSingleWallet = useSelector(
    (state) => state.authReducer.isSingleWallet
  );

  const storeId = useSelector(
    (state) => state.storeReducer?.storelist[0]?.store_id
  );

  const refreshTimestamp = localStorage.getItem("refreshTimestamp");

  const togglePayModal = () => {
    setShowPaymentModal((prevState) => !prevState);
    // handleClose();
  };

  const toggleMessageModal = () => {
    setShowMessageModal((prevState) => !prevState);
  };

  const handleCallBack = (accountFormXrp) => {
    merchantApi
      .post(API.GET_WALLET, {
        address: accountFormXrp.address,
        store_id: storeId,
      })
      .then((res) => {
        localStorage.removeItem("accountDetail");
        localStorage.setItem("accountDetail", JSON.stringify(accountFormXrp));
        // handleSelectedWalletType('merchant');
        setIsWalletDetailsVisible(true);
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Something went wrong");
      });
  };

  useEffect(() => {
    if (isSingleWallet === null || isSingleWallet === undefined) {
      setWalletInitializationLoading(true);
    }
    getConfigData();
    getBalance();
    getTransactions();
    getBusinessDetails();
    getPayoutsThisMonth();
    dispatch(setSingleWallet());
  }, [dispatch]);

  useEffect(() => {
    getRevenueForBarChart();
  }, [timePeriod, refreshAnalytics]);

  useEffect(() => {
    getRevenueForReportsChart();
    getGroupByReceipt();
    getCustomerReport();
    getMembersReport();
  }, [reportTimePeriod, refreshReports]);

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const getConfigData = () => {
    merchantApi
      .get(API.CONFIG)
      .then((response) => {
        const { data } = response;
        if (
          data.data.store_wise_wallet === null ||
          data.data.store_wise_wallet === undefined
        ) {
          setIsCreateWalletVisible(true);
        }
        setWalletInitializationLoading(false);
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Something went wrong");
        setWalletInitializationLoading(false);
      });
  };

  const getTransactions = async () => {
    try {
      setTableLoading(true);
      const response = await merchantApi.get(
        `${API.TRANSACTION_LIST}?limit=3`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );
      setTableLoading(false);
      setTranslist(response.data);
    } catch (error) {
      setTableLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  const getBalance = async () => {
    setBalanceLoading(true);
    try {
      const response = await merchantApi.get(API.GET_BALANCE, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
      });
      setBalance(response.data.data.total_balance);
      setUserCurrency({
        symbol: response.data.data.currency.symbol,
        decimalPlace: response.data.data.currency.decimal_place,
        symbolPrefix: response.data.data.currency.symbol_prefix,
      });
      setBalanceLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setBalanceLoading(false);
    }
  };

  const getPayoutsThisMonth = async () => {
    setPayoutLoading(true);
    try {
      const response = await merchantApi.get(
        `${API.GET_REVENUE}/?group_by=month&type=withdraw`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );

      const lastMonth =
        response?.data?.data.group_by_result[0].withdraw.length - 1;

      setPayoutsThisMonth(
        response.data.data.group_by_result[0].withdraw[lastMonth].value
      );
      setPayoutLoading(false);
    } catch (error) {
      console.log(error);
      setPayoutLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  const getRevenueForBarChart = async () => {
    setPrevTransTime("--");
    setCurrentTransTime("--");
    setFetchChartDataLoading(true);
    try {
      const response = await merchantApi.get(
        `${API.GET_REVENUE}/?group_by=${timePeriod}`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );

      setCurrency({
        symbol: response.data.data.currency.symbol,
        code: response.data.data.currency.code,
      });

      const receipt = response.data.data.group_by_result[0].receipt.slice(5);
      const refund = response.data.data.group_by_result[1].refund.slice(5);
      const withdraw = response.data.data.group_by_result[2].withdraw.slice(5);

      if (receipt[receipt.length - 1].last_transaction_datetime) {
        setCurrentTransTime(
          `${moment
            .parseZone(receipt[receipt.length - 1].last_transaction_datetime)
            .format("hh:mm A")} (UTC)`
        );
      }
      if (receipt[receipt.length - 2].last_transaction_datetime) {
        setPrevTransTime(
          `${moment
            .parseZone(receipt[receipt.length - 2].last_transaction_datetime)
            .format("hh:mm A")} (UTC)`
        );
      }

      // create chart data for bar charts, depending on timeperiod
      let data_length = 6;

      switch (timePeriod) {
        case "day":
          const dayChartData = createDayChartData(receipt, refund, withdraw);
          data_length = dayChartData.length;
          setChartData(dayChartData);
          setDataPointName({
            current: "Today",
            previous: "Yesterday",
            currentReceipt: dayChartData[data_length - 1].Receipt,
            previousReceipt: dayChartData[data_length - 2].Receipt,
          });
          break;

        case "week":
          const weekChartData = createWeekChartData(receipt, refund, withdraw);
          data_length = weekChartData.length;
          setChartData(weekChartData);
          setDataPointName({
            current: "This Week",
            previous: "Last Week",
            currentReceipt: weekChartData[data_length - 1].Receipt,
            previousReceipt: weekChartData[data_length - 2].Receipt,
          });
          break;

        case "month":
          const monthChartData = createYearOrMonthChartData(
            receipt,
            refund,
            withdraw
          );
          data_length = monthChartData.length;

          setChartData(monthChartData);
          setDataPointName({
            current: "This Month",
            previous: "Last Month",
            currentReceipt: monthChartData[data_length - 1].Receipt,
            previousReceipt: monthChartData[data_length - 2].Receipt,
          });
          break;

        case "year":
          const yearChartData = createYearOrMonthChartData(
            receipt,
            refund,
            withdraw
          );
          data_length = yearChartData.length;
          setChartData(yearChartData);
          setDataPointName({
            current: "This Year",
            previous: "Last Year",
            currentReceipt: yearChartData[data_length - 1].Receipt,
            previousReceipt: yearChartData[data_length - 2].Receipt,
          });
          break;

        default:
          setDataPointName({
            current: "Today",
            previous: "Yesterday",
            currentReceipt: "$0",
            previousReceipt: "$0",
          });
          break;
      }
      setFetchChartDataLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setFetchChartDataLoading(false);
    }
  };

  const getRevenueForReportsChart = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, revenue: true }));
      const response = await merchantApi.get(
        `${API.GET_SALES}?from_date=${reportTimePeriod.from}&to_date=${reportTimePeriod.to}`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );

      const salesReportData = createReportChartData(
        response.data.data.amount_history
      );
      const averageTransactionAmountData = createReportChartData(
        response.data.data.average_history
      );

      const numberOfReceiptTransactionsData = createReportChartData(
        response.data.data.count_history
      );

      setSalesReport({
        salesReportData,
        averageTransactionAmountData,
        numberOfReceiptTransactionsData,
        totalReceipt: response.data.data.total_receipt,
        averageTransactionAmount: response.data.data.average_transaction,
        transactionCount: response.data.data.transaction_count,
      });

      setReportLoading((prevState) => ({ ...prevState, revenue: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, revenue: false }));
    }
  };

  const getGroupByReceipt = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, receipt: true }));
      const response = await merchantApi.get(
        `${API.GROUP_BY_RECEIPT}?from_date=${reportTimePeriod.from}&to_date=${reportTimePeriod.to}`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );

      const walletList = response.data.data[0].result.sort(
        (a, b) => b.value - a.value
      );
      const sorteList = response.data.data[1].result.sort(
        (a, b) => b.value - a.value
      );

      const topThree = sorteList.slice(0, 3);
      const remainingStoresTotalValue = sorteList
        .slice(4)
        .reduce((prev, curr) => prev + curr.value, 0);

      const finalStoreList = [
        ...topThree,
        { name: "Others", value: remainingStoresTotalValue },
      ];

      const storeWiseData = createDoughnutChartData(finalStoreList, "store");

      const walletWiseData = createDoughnutChartData(walletList, "wallet");
      setGroupByReceiptReport({
        storeWiseData: storeWiseData,
        storeTotalReceipt: response.data.data[1].total_receipt,
        walletWiseData: walletWiseData,
        walletTotalReceipt: response.data.data[0].total_receipt,
      });
      // setSalesReport((prevState) => return { ...prevState, storeWiseData });

      setReportLoading((prevState) => ({ ...prevState, receipt: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, receipt: false }));
    }
  };

  const getCustomerReport = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, customer: true }));
      const response = await merchantApi.get(
        `${API.GET_CUSTOMER_REPORT}?from_date=${reportTimePeriod.from}&to_date=${reportTimePeriod.to}`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );
      const customerBifurcationData = createCustomerBifurcationData(
        response.data.data.group_by_customers
      );

      const newCustomers = response.data.data.new_customer;
      const repeatCustomers = response.data.data.old_customer;

      const retentionPercent =
        newCustomers === 0 && repeatCustomers === 0
          ? 0
          : (repeatCustomers / (newCustomers + repeatCustomers)) * 100;

      setCustomerReport({
        retentionPercent,
        customerBifurcationData,
        customerRetentionData: [
          {
            name: "Repeat Customers",
            value: repeatCustomers,
            fill: doughnutChartColors[0],
          },
          {
            name: "New Customers",
            value: newCustomers,
            fill: doughnutChartColors[1],
          },
        ],
      });

      setReportLoading((prevState) => ({ ...prevState, customer: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, customer: false }));
    }
  };

  const getMembersReport = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, members: true }));
      const response = await merchantApi.get(
        `${API.GET_MEMBERS_REPORT}?from_date=${reportTimePeriod.from}&to_date=${reportTimePeriod.to}`,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
        }
      );
      const memberData = createReportChartData(response.data.data.history);

      setMemberReport({
        totalMembers: response.data.data.total_member,
        memberData,
      });
      setReportLoading((prevState) => ({ ...prevState, members: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, members: false }));
    }
  };

  // Reports charts data

  const createDoughnutChartData = (data, type) => {
    if (type === "store") {
      return data.map((item, i) => ({
        name: capitalize(item.name),
        value: Number(item.value),
        fill: doughnutChartColors[i],
      }));
    } else {
      return data.map((item, i) => ({
        name: item.name.toUpperCase(),
        value: Number(item.value),
        fill: doughnutChartColors[i],
      }));
    }
  };

  const createReportChartData = (data) => {
    return data.map((item) => ({
      name: item.period.split(" ").slice(1).join(" "),
      value: Number(item.value),
    }));
  };

  // Bar chart data
  const createDayChartData = (receipt, refund, withdraw) => {
    return receipt.length > 0
      ? receipt.map((item, i) => {
          return {
            name: item.period.split(" ").slice(1).join(" "),
            Receipt: item.value,
            Withdrawals: withdraw[i].value,
            Refunds: refund[i].value,
          };
        })
      : [];
  };

  const createCustomerBifurcationData = (customerData) => {
    return customerData.map((data) => {
      return {
        name: data.period.split(" ").slice(1).join(" "),
        Repeat: Number(data.old_customer_no),
        New: Number(data.new_customer_no),
      };
    });
  };

  const createWeekChartData = (receipt, refund, withdraw) => {
    return receipt.length > 0
      ? receipt.map((item, i) => {
          return {
            name: item.period.split(" ").slice(2).join(" "),
            Receipt: item.value,
            Withdrawals: withdraw[i].value,
            Refunds: refund[i].value,
          };
        })
      : [];
  };

  const createYearOrMonthChartData = (receipt, refund, withdraw) => {
    return receipt.length > 0
      ? receipt.map((item, i) => {
          return {
            name: item.period,
            Receipt: item.value,
            Withdrawals: withdraw[i].value,
            Refunds: refund[i].value,
          };
        })
      : [];
  };

  const handleSelectedWalletType = (walletType) => {
    console.log(walletType, "Dashboard");
    setIsCreateWalletVisible(false);
    if (walletType === "merchant") {
      setIsWalletDetailsVisible(true);
      // setWalletInitializationLoading(false);
    }
  };

  const handleDateFilter = (value) => {
    console.log(value);
    setReportTimePeriod({
      from: format(value[0], "yyyy-MM-dd"),
      to: format(value[1], "yyyy-MM-dd"),
    });
    console.log(new Date(format(value[0], "yyyy-MM-dd")));
  };

  const timePeriodChangeHandler = (period) => {
    const periodData = timePeriodOptions.filter(
      (option) => option.value === period
    );

    setTimePeriod(period);
    setTimePeriodTitle(periodData[0].name);
  };

  const reportTimePeriodChangeHandler = (from, to, name) => {
    // const periodData = timePeriodOptions.filter(
    //   (option) => option.value === period
    // );
    console.log(from, to);
    // console.log(new Date(from))
    setCalenderValue([new Date(from), new Date(to)]);
    setReportTimePeriod({
      from,
      to,
    });
    setReportTimePeriodTitle(name);
  };
  const toggleRevenue = () => {
    if (!showReturn && !showWithdrawal) {
      setShowReturn(true);
      setShowRevenue(true);
      setShowWithdrawal(true);
      return;
    }
    setShowRevenue((prevState) => !prevState);
  };
  const toggleReturn = () => {
    if (!showRevenue && !showWithdrawal) {
      setShowReturn(true);
      setShowRevenue(true);
      setShowWithdrawal(true);
      return;
    }
    setShowReturn((prevState) => !prevState);
  };
  const toggleWithdrawal = () => {
    if (!showReturn && !showRevenue) {
      setShowReturn(true);
      setShowRevenue(true);
      setShowWithdrawal(true);
      return;
    }
    setShowWithdrawal((prevState) => !prevState);
  };

  // DataGrid
  const createDataRow = (transaction) => {
    return {
      id: transaction.reference_number,
      lastUpdate: moment
        .parseZone(transaction.modified_date)
        .format("Do MMM YYYY"),

      sender: startCase(toLower(transaction?.sender?.name)),
      receiver: startCase(toLower(transaction?.receiver?.name)),
      store: startCase(transaction?.store?.name || "N/A"),
      counter: transaction?.counter?.name,
      value: formatCurrency(
        transaction?.receive_amount,
        transaction?.receiver_coin?.decimal_place,
        transaction?.receiver_coin?.symbol,
        transaction?.receiver_coin?.symbol_prefix
      ),
      valueInCurrency: formatCurrency(
        transaction?.value_in_currency,
        transaction?.currency?.decimal_place,
        transaction?.currency?.symbol,
        transaction?.currency?.symbol_prefix
      ),
      // currency: transaction.currency.code,
      type: startCase(transaction.type),
      status:
        transaction.status.value === "processed"
          ? "Processed"
          : transaction.status.value === "pending_admin_approval"
          ? "Pending"
          : "Failed",

      details: transaction.blockchain_url,
      transactionData: transaction,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 80,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
    },
    {
      field: "lastUpdate",
      headerName: "Last Updated",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",

      sortable: false,
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1.5,
      minWidth: 120,
      // maxWidth: 200,

      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1.5,
      minWidth: 120,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.formattedValue}</p>;
      },
    },
    {
      field: "store",
      headerName: "Store",
      flex: 1,
      // minWidth: 80,
      // maxWidth: 200,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "counter",
      headerName: "Counter",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1.5,
      minWidth: 100,
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
    },

    {
      field: "valueInCurrency",
      headerName: "Value in Currency",
      flex: 1.5,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
    },
    // {
    //   field: 'currency',
    //   headerName: 'Currency',
    //   flex: 1,
    //   minWidth: 80,
    //   // maxWidth: 200,
    //   headerClassName: 'header',
    //   sortable: false,
    // },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 80,
      // maxWidth: 150,
      headerClassName: "header",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,

      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return params.formattedValue === "Processed" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
            avatar={
              <img
                src={greentick}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="tick"
              />
            }
            label="Successful"
            color="success"
          />
        ) : params.formattedValue === "Pending" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#FFF7CD",
              color: "rgb(122, 79, 1)",
            }}
            avatar={
              <img
                src={warning}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="corss"
              />
            }
            color="warning"
            label="Pending"
          />
        ) : (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#f8dada",
              color: "#B42318",
            }}
            avatar={
              <img
                src={cross}
                style={{ width: "13px", marginLeft: "10px" }}
                alt="corss"
              />
            }
            color="error"
            label="Failed"
          />
        );
      },
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      flex: 1.5,
      headerClassName: "header",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <a
            href={params.formattedValue}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              appearance="default"
              className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
            >
              <span className="text-success">View Details</span>
            </Button>
          </a>
        );
      },
    },
  ];

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id === id);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8 bg-white">
        <div className="tabing stb-h-100 p-xl-4 p-3">
          {walletInitializationLoading ? (
            <Loader
              backdrop
              content="Please wait setting up your account."
              vertical
              style={{ zIndex: "9999", height: "100%" }}
            />
          ) : (
            <>
              <div className="reports bg-white radi">
                <div className="d-flex align-items-center pb-4 space-between">
                  <div className="col-lg-3">
                    <h3 className="m-0 page-title">
                      Analytics
                      <span
                        className="sync"
                        onClick={() => {
                          localStorage.setItem(
                            "refreshTimestamp",
                            new Date().getTime()
                          );
                          setRefreshAnalytics((prevState) => prevState + 1);
                        }}
                      >
                        {" "}
                        <img
                          className={`${
                            fetchChartDataLoading ? "reload" : null
                          }`}
                          src={reload}
                          alt="reload"
                        />
                      </span>
                    </h3>
                    <p>View your stats</p>
                  </div>
                  <div className="d-flex align-items-center justify-end gap-4">
                    <div style={{ width: "fit-content" }}>
                      <Button
                        onClick={() => {
                          history.push("/merchantprofile/withdrawl");
                        }}
                        style={{ minHeight: "40px" }}
                        appearance="default"
                        className="btn load text-white d-flex flex-row gap-2"
                      >
                        <span>Payout Funds</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="dash-hero">
                  <div className="hero-graph" style={{ paddingBottom: 0 }}>
                    <div className="hero-filters">
                      <div>
                        <p className="report-title">Receipt</p>
                        <p>{dataPointName.current}</p>
                        <h3 className="page-title">
                          {formatCurrency(
                            dataPointName.currentReceipt,
                            userCurrency.decimalPlace,
                            userCurrency.symbol,
                            userCurrency.symbolPrefix
                          )}
                          {/* {currency.symbol} {dataPointName.currentRevenue} */}
                        </h3>
                        <p>
                          {currentTransTime === "" ? "--" : currentTransTime}
                        </p>
                      </div>
                      <div>
                        <p className="report-title">Receipt</p>
                        <p>{dataPointName.previous}</p>
                        <h3 className="page-title">
                          {formatCurrency(
                            dataPointName.previousReceipt,
                            userCurrency.decimalPlace,
                            userCurrency.symbol,
                            userCurrency.symbolPrefix
                          )}
                          {/* {currency.symbol} {dataPointName.previousRevenue} */}
                        </h3>
                        <p>{prevTransTime === "" ? "--" : prevTransTime}</p>
                      </div>
                      <div>
                        <Dropdown align="end" className="w-100 home-dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            {timePeriodTitle || "Select Period"}
                            <img
                              src={dropDown}
                              style={{ marginLeft: "4px" }}
                              alt="drop-down"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="super-colors">
                            {timePeriodOptions.map((period, i) => (
                              <Dropdown.Item
                                // as="button"
                                value={period.value}
                                onClick={() => {
                                  timePeriodChangeHandler(period.value);
                                }}
                                key={i + "res"}
                              >
                                {period.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="hero-stat-type">
                        <div className="stat" onClick={toggleRevenue}>
                          <span className="stat-selector yellow-dark"></span>
                          <span>Receipt</span>
                        </div>
                        <div className="stat" onClick={toggleReturn}>
                          <span className="stat-selector yellow"></span>
                          <span>Refunds</span>
                        </div>
                        <div className="stat" onClick={toggleWithdrawal}>
                          <span className="stat-selector yellow-light"></span>
                          <span>Withdrawals</span>
                        </div>
                      </div>
                    </div>
                    <div className="hero-chart">
                      {fetchChartDataLoading ? (
                        <Loader content="loading..." />
                      ) : (
                        <ResponsiveContainer height="95%" width="100%">
                          <BarChart
                            width={400}
                            height={300}
                            data={chartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 0,
                            }}
                            barCategoryGap={20}
                          >
                            <Tooltip />
                            <XAxis
                              scale="point"
                              dataKey="name"
                              interval="preserveStartEnd"
                            />
                            {showRevenue && (
                              <Bar
                                barSize={10}
                                dataKey="Receipt"
                                fill="#B38600"
                                radius={[10, 10, 0, 0]}
                              />
                            )}
                            {showReturn && (
                              <Bar
                                barSize={10}
                                dataKey="Refunds"
                                fill="#FCBD00"
                                radius={[10, 10, 0, 0]}
                              />
                            )}
                            {showWithdrawal && (
                              <Bar
                                barSize={10}
                                dataKey="Withdrawals"
                                fill="#FDD354"
                                radius={[10, 10, 0, 0]}
                              />
                            )}
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                  <div className="hero-numbers">
                    <div className="hero-balance">
                      <div style={{ position: "relative" }}>
                        <p>
                          {userCurrency.symbol} Balance
                          <span
                            className="sync"
                            onClick={() => {
                              localStorage.setItem(
                                "refreshTimestamp",
                                new Date().getTime()
                              );
                              getBalance();
                            }}
                          >
                            {" "}
                            <img
                              className={`${balanceLoading ? "reload" : null}`}
                              src={reload}
                              alt="reload"
                            />
                          </span>{" "}
                        </p>
                        {balanceLoading ? (
                          <div style={{ position: "absolute", top: "50%" }}>
                            <Loader content="loading..." />
                          </div>
                        ) : (
                          <h3 className="page-title">
                            {formatCurrency(
                              balance,
                              userCurrency.decimalPlace,
                              userCurrency.symbol,
                              userCurrency.symbolPrefix
                            )}
                          </h3>
                        )}
                      </div>
                    </div>
                    {userRole !== "cashier" && (
                      <div className="hero-payout">
                        <div style={{ position: "relative" }}>
                          <p>
                            Payouts this month{" "}
                            <span
                              className="sync"
                              onClick={() => {
                                localStorage.setItem(
                                  "refreshTimestamp",
                                  new Date().getTime()
                                );
                                getPayoutsThisMonth();
                              }}
                            >
                              {" "}
                              <img
                                className={`${payoutLoading ? "reload" : null}`}
                                src={reload}
                                alt="reload"
                              />
                            </span>{" "}
                          </p>
                          {payoutLoading ? (
                            <div style={{ position: "absolute", top: "50%" }}>
                              <Loader content="loading..." />
                            </div>
                          ) : (
                            <h3 className="page-title">
                              {formatCurrency(
                                payoutsThisMonth,
                                userCurrency.decimalPlace,
                                userCurrency.symbol,
                                userCurrency.symbolPrefix
                              )}
                              {/* {payoutsThisMonth} {currency.symbol} */}
                            </h3>
                          )}
                        </div>
                        <Button
                          className="btn white-btn action-button basis-30"
                          onClick={() => {
                            history.push({
                              pathname: "/reports/transaction/",
                              state: { tab: "withdraw" },
                            });
                          }}
                          appearance="subtle"
                        >
                          View
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center  pt-4 space-between">
                <div className="col-lg-4">
                  <h6 className="m-0" style={{ color: "black" }}>
                    Recent Transactions
                    <span
                      className="sync"
                      onClick={() => {
                        localStorage.setItem(
                          "refreshTimestamp",
                          new Date().getTime()
                        );
                        getTransactions();
                      }}
                    >
                      {" "}
                      <img
                        className={`${tableLoading ? "reload" : null}`}
                        src={reload}
                        alt="reload"
                      />
                    </span>
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-end gap-4">
                  <div style={{ width: "fit-content" }}>
                    <Button
                      onClick={() => {
                        history.push("/transactions/reporttransactions");
                      }}
                      style={{ minHeight: "40px" }}
                      appearance="default"
                      className="btn white-btn action-button basis-30"
                    >
                      <span>View All</span>
                    </Button>
                  </div>
                </div>
              </div>

              <Paper sx={{ width: "100%" }}>
                <Box
                  sx={{
                    height: 238,
                    width: "100%",
                    overflow: "auto",
                    // minWidth: 900,
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                      "& .MuiDataGrid-row": {
                        // backgroundColor: 'rgba(235, 235, 235, .7)',
                        fontWeight: 500,
                        color: "#667085",
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      },
                    },
                    "& .header": {
                      backgroundColor: "#F4F4F4",
                      "& div": {
                        "& div": {
                          "& div": {
                            "& div": {
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "Inter, sans-serif",
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  <DataGrid
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
                      width: "100%",
                      minWidth: "1400px",
                    }}
                    // componentsProps={{
                    //   cell: {
                    //     onMouseEnter: handlePopoverOpen,
                    //     onMouseLeave: handlePopoverClose,
                    //   },
                    // }}
                    components={{
                      LoadingOverlay: LinearProgress,
                    }}
                    rows={rows}
                    columns={columns}
                    rowHeight={60}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    hideFooter
                    loading={tableLoading}
                  />
                  {/* <Popover
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1, borderRadius: '10px' }}>
                      {value}
                    </Typography>
                  </Popover> */}
                </Box>
              </Paper>

              <section>
                <div className="d-flex align-items-start flex-column mt-4 gap-2">
                  <div className="col-lg-4">
                    <h3 className="m-0 page-title">
                      Reports Overview
                      <span
                        className="sync"
                        onClick={() => {
                          localStorage.setItem(
                            "refreshTimestamp",
                            new Date().getTime()
                          );
                          setRefreshReports((prevState) => prevState + 1);
                        }}
                      >
                        {" "}
                        <img
                          className={`${
                            reportLoading.customer ||
                            reportLoading.members ||
                            reportLoading.receipt ||
                            reportLoading.revenue
                              ? "reload"
                              : null
                          }`}
                          src={reload}
                          alt="reload"
                        />
                      </span>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center justify-end gap-4 flex-sm-row flex-column">
                    <Dropdown as={ButtonGroup} className="w-100">
                      <Dropdown.Toggle id="dropdown-basic">
                        {reportTimePeriodTitle || "Select Period"}
                        <img
                          className="dropdown-icon"
                          src={dropDown}
                          style={{ marginLeft: "4px" }}
                          alt="drop-down"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="super-colors">
                        {reportTimePeriods.map((period, i) => (
                          <Dropdown.Item
                            as="button"
                            value={period.name}
                            onClick={() => {
                              reportTimePeriodChangeHandler(
                                period.from,
                                period.to,
                                period.name
                              );
                            }}
                            key={i + "res"}
                          >
                            {period.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    <DateRangePicker
                      cleanable
                      placeholder="Filter Date"
                      className="w-100 dateInput"
                      size="lg"
                      style={{ height: "40px" }}
                      onOk={handleDateFilter}
                      value={calenderValue}
                      placement="auto"
                      showOneCalendar={true}
                      disabledDate={afterToday()}
                      onClean={(dara) => {
                        reportTimePeriodChangeHandler(
                          reportTimePeriods[0].from,
                          reportTimePeriods[0].to,
                          reportTimePeriods[0].name
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="reports-container mt-4">
                  <div className="report">
                    <p className="report-title">Sales Volume</p>
                    <p className="report-data">
                      {formatCurrency(
                        salesReport.totalReceipt,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                      {/* {currency.symbol} {salesReport.totalReceipt} */}
                    </p>
                    {reportLoading.revenue ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <AreaChartComp
                        data={salesReport.salesReportData}
                        bottom={0}
                        label="Value"
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Sales Breakup by Currency</p>
                    <p className="report-data">
                      {formatCurrency(
                        groupByReceiptReport.walletTotalReceipt,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                      {/* {currency.symbol} {groupByReceiptReport.walletTotalReceipt} */}
                    </p>
                    {reportLoading.receipt ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <DoughnutChartComp
                        data={groupByReceiptReport.walletWiseData}
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Team Size</p>
                    <p className="report-data">{memberReport.totalMembers}</p>
                    {reportLoading.members ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <ResponsiveContainer height="95%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={memberReport.memberData}
                          margin={{
                            left: 5,
                            right: 5,
                            top: 5,
                            bottom: 30,
                          }}
                          barCategoryGap={20}
                        >
                          <Tooltip
                            content={<CustomTooltip valueLabel="Count" />}
                          />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            interval="preserveStartEnd"
                          />

                          <Bar
                            barSize={10}
                            dataKey="value"
                            fill="#B38600"
                            radius={[10, 10, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Sales Breakup by Store</p>
                    <p className="report-data">
                      {formatCurrency(
                        groupByReceiptReport.storeTotalReceipt,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                      {/* {currency.symbol} {groupByReceiptReport.storeTotalReceipt} */}
                    </p>
                    {reportLoading.receipt ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <DoughnutChartComp
                        data={groupByReceiptReport.storeWiseData}
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Avg Spend Per Transaction</p>
                    <p className="report-data">
                      {formatCurrency(
                        salesReport.averageTransactionAmount,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                      {/* {currency.symbol} {salesReport.averageTransactionAmount} */}
                    </p>
                    {reportLoading.revenue ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <AreaChartComp
                        data={salesReport.averageTransactionAmountData}
                        bottom={0}
                        label="Value"
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Total Receipts</p>
                    <p className="report-data">
                      {salesReport.transactionCount}
                    </p>
                    {reportLoading.revenue ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <AreaChartComp
                        data={salesReport.numberOfReceiptTransactionsData}
                        bottom={0}
                        label="Count"
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Customer Retention</p>
                    <p className="report-data">
                      {customerReport.retentionPercent}%
                    </p>
                    {reportLoading.customer ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <DoughnutChartComp
                        data={customerReport.customerRetentionData}
                      />
                    )}
                  </div>

                  <div className="report">
                    <p className="report-title">Customer Bifurcation</p>
                    <p className="report-data">
                      {customerReport.retentionPercent}%
                    </p>
                    {reportLoading.customer ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader content="loading..." />
                      </div>
                    ) : (
                      <ResponsiveContainer height="95%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={customerReport.customerBifurcationData}
                          margin={{
                            left: 5,
                            right: 5,
                            top: 5,
                            bottom: 30,
                          }}
                          barCategoryGap={20}
                        >
                          <Tooltip />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            interval="preserveStartEnd"
                          />

                          <Bar
                            barSize={10}
                            dataKey="New"
                            fill="#B38600"
                            radius={[10, 10, 0, 0]}
                          />
                          <Bar
                            barSize={10}
                            dataKey="Repeat"
                            fill="#FCBD00"
                            radius={[10, 10, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </div>

      {isCreateWalletVisible && (
        <CreateWalletModal
          open={isCreateWalletVisible}
          handleClose={() => {
            setIsCreateWalletVisible(!isCreateWalletVisible);
            setIsWalletDetailsVisible(!isWalletDetailsVisible);
          }}
          setWalletDetails={setWalletDetails}
        />
      )}

      {isWalletDetailsVisible && (
        <ShowWalletDetails
          open={isWalletDetailsVisible}
          handleClose={() => setIsWalletDetailsVisible(!isWalletDetailsVisible)}
          walletDetails={walletDetails}
        />
      )}

      {isMerchantWalletAddressModel && (
        <MerchantWalletAddress
          userCurrency={userCurrency}
          open={isMerchantWalletAddressModel}
          handleClose={() =>
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel)
          }
          togglePayModal={togglePayModal}
          toggleMessageModal={toggleMessageModal}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          userCurrency={userCurrency}
          open={showPaymentModal}
          togglePayModal={togglePayModal}
          goBack={() => {
            togglePayModal();
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel);
          }}
        />
      )}
      {showMessageModal ? (
        <MessageModal
          open={showMessageModal}
          onClose={() => {
            toggleMessageModal();
            history.push("/");
          }}
        />
      ) : null}
    </>
  );
};
