import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import DevDashboard from "./dashboard/DevDashboard";
import WalletMenu from "./merchantprofile/WalletMenu/WalletMenu";
import Statement from "./merchantprofile/Statement";
import Withdrawl from "./merchantprofile/Withdrawl";
import { History, Balance, ReportTransactions } from "./transaction";
import { Transactions } from "./reports";
// Its changed to a modal
// import AddSystemUser from './administration/AddSystemUser';
import UserApproval from "./administration/UserApproval";
import {
  SystemUser,
  BusinessDetails,
  StoreMenu,
  Counter,
} from "./administration";

// import BusinessDetails from "./administration/BusinessDetails";
// import BusinessDetailsEdit from "./kycdone/BusinessDetailsEdit";
import Invoices from "./Invoices";
import Customer from "./Customer";
import CustomerDetails from "./CustomerDetails";
import Support from "./support/Support";
// import NewTicket from './support/component/NewTicket';
import TicketReply from "./support/component/TicketReply";
import Settings from "./Account/Settings";
import BootstrapTables from "./tables/BootstrapTables";
// import Signin from "./stb/Signin";
// import Signup from "./stb/Signup";
import ForgotPassword from "./stb/ForgotPassword";
import ChangePassword from "./stb/ChangePassword";
import ResetPassword from "./stb/ResetPassword";
import Thankyou from "./stb/Thankyou";
import SignupThanks from "./stb/SingupThanks";
import Lock from "./stb/Lock";
import NotFoundPage from "./stb/NotFound";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import Authentication from "./stb/Authentication";
import { UserChat } from "./userChat";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  let token = localStorage.getItem("token");

  if (!token) {
    return <Redirect to="/signin" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className="content">
            <Navbar />
            <Component {...props} />
          </main>
        </>
      )}
    />
  );
};

export default ({ isAuthenticate }) => {
  return (
    <Switch>
      {/* <RouteWithLoader exact path={Routes.Signin.path} component={Signin} /> */}
      {/* <RouteWithLoader exact path={Routes.Signup.path} component={Signup} /> */}
      <RouteWithLoader
        exact
        path={Routes.Signin.path}
        component={Authentication}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={`${Routes.ChangePassword.path}/`}
        component={ChangePassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader exact path={Routes.Thankyou.path} component={Thankyou} />
      <RouteWithLoader
        exact
        path={Routes.Signupthanks.path}
        component={SignupThanks}
      />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />

      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DevDashboard}
      />
      {/* <RouteWithSidebar
      exact
      path={Routes.DevDashboard.path}
      component={DevDashboard}
    /> */}
      {/* <RouteWithSidebar exact path={Routes.Balance.path} component={Balance} /> */}
      <RouteWithSidebar exact path={Routes.Menu.path} component={WalletMenu} />
      <RouteWithSidebar
        exact
        path={Routes.ReportTransactions.path}
        component={ReportTransactions}
      />
      <RouteWithSidebar
        exact
        path={Routes.Statement.path}
        component={Statement}
      />
      <RouteWithSidebar
        exact
        path={Routes.Withdrawl.path}
        component={Withdrawl}
      />
      <RouteWithSidebar
        exact
        path={Routes.Transaction.path}
        component={Transactions}
      />

      <RouteWithSidebar exact path={Routes.History.path} component={History} />
      <RouteWithSidebar
        exact
        path={Routes.SystemUser.path}
        component={SystemUser}
      />
      {/* <RouteWithSidebar */}
      {/* <RouteWithSidebar
      exact
      path={Routes.AddSystemUser.path}
      component={AddSystemUser}
    /> */}
      <RouteWithSidebar
        exact
        path={Routes.UserApproval.path}
        component={UserApproval}
      />
      <RouteWithSidebar
        exact
        path={Routes.BusinessDetails.path}
        component={BusinessDetails}
      />
      <RouteWithSidebar
        // <RouteWithSidebar
        exact
        path={Routes.CounterMenu.path}
        component={Counter}
      />
      <RouteWithSidebar
        exact
        path={Routes.StoreMenu.path}
        component={StoreMenu}
      />
      {/* <RouteWithSidebar exact path={Routes.BusinessDetailsEdit.path} component={BusinessDetailsEdit} /> */}
      <RouteWithSidebar
        exact
        path={Routes.Invoices.path}
        component={Invoices}
      />
      <RouteWithSidebar
        exact
        path={Routes.Customer.path}
        component={Customer}
      />
      <RouteWithSidebar
        exact
        path={Routes.CustomerDetails.path}
        component={CustomerDetails}
      />

      {/* <RouteWithSidebar exact path={Routes.Support.path} component={Support} /> */}
      {/* <RouteWithSidebar
      exact
      path={Routes.NewTicket.path}
      component={NewTicket}
    /> */}
      {/* <RouteWithSidebar
        exact
        path={Routes.TicketReply.path}
        component={TicketReply}
      /> */}
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />
      <RouteWithSidebar
        exact
        path={Routes.BootstrapTables.path}
        component={BootstrapTables}
      />

      {/* components */}
      <RouteWithSidebar
        exact
        path={Routes.Accordions.path}
        component={Accordion}
      />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar
        exact
        path={Routes.Breadcrumbs.path}
        component={Breadcrumbs}
      />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar
        exact
        path={Routes.Pagination.path}
        component={Pagination}
      />
      <RouteWithSidebar
        exact
        path={Routes.Popovers.path}
        component={Popovers}
      />
      <RouteWithSidebar
        exact
        path={Routes.Progress.path}
        component={Progress}
      />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar
        exact
        path={Routes.Tooltips.path}
        component={Tooltips}
      />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />
      <RouteWithSidebar
        path={Routes.UserChat.path}
        // permissionLoading={permissionLoading}
        // isAllowed={true}
        component={UserChat}
      />

      <RouteWithSidebar
        exact
        path={Routes.UserChatGeneral.path}
        // permissionLoading={permissionLoading}
        // isAllowed={true}
        component={UserChat}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
