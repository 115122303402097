import React from "react";
import Paper from "@mui/material/Paper";
import * as Rsuite from "rsuite";
import moment from "moment-timezone";
import { startCase, toLower } from "lodash";

import BTC from "../../assets/img/icons/BTC.svg";
import XRP from "../../assets/img/icons/XRP.svg";
import LTC from "../../assets/img/icons/LTC.svg";
import ELS from "../../assets/img/icons/ELS.png";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import { formatCurrency } from "../../utils/common";

const cellStyle = {
  color: "black",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function WithdrawTransactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  loading,
}) {
  console.log(translist);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: +event.target.value,
    });
  };

  const createDataRow = (transaction) => ({
    id: transaction.reference_number,
    coin: transaction.sender_coin.symbol,
    lastUpdate: moment
      .parseZone(transaction.modified_date)
      .format("Do MMM YYYY"),

    sender: startCase(toLower(transaction.sender?.name)),
    receiver: startCase(toLower(transaction.receiver?.name)),
    value: formatCurrency(
      transaction.send_amount,
      transaction.sender_coin.decimal_place,
      transaction.sender_coin.symbol
    ),
    valueInCurrency: formatCurrency(
      transaction.value_in_currency,
      transaction.currency.decimal_place,
      transaction.currency.symbol,
      transaction.currency.symbol_prefix
    ),
    details: transaction.blockchain_url,
    logo: transaction.sender_coin.logo,
    // currency: transaction.currency.code,
    // type: startCase(toLower(transaction.type)),
    // status: transaction.status.value,
  });

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "coin ",
      headerName: "Coin",
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <strong>
            <img
              style={{ width: "40px", height: "40px", marginRight: "12px" }}
              src={params.row.logo}
              alt="coin"
            />
            {params.row.coin}
          </strong>
        );
      },
    },

    {
      field: "lastUpdate",
      headerName: "Last Updated",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
    },

    {
      field: "valueInCurrency",
      headerName: "Value in Currency",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      flex: 1.5,
      headerClassName: "header",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <a
            href={params.formattedValue}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Rsuite.Button
              appearance="default"
              className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
            >
              <span className="text-success">View Details</span>
            </Rsuite.Button>
          </a>
        );
      },
    },
    // {
    //   field: 'currency',
    //   headerName: 'Currency',
    //   flex: 1,
    //   minWidth: 120,
    //   // maxWidth: 200,
    //   headerClassName: 'header',
    // },
    // {
    //   field: 'type',
    //   headerName: 'Type',
    //   flex: 1,
    //   minWidth: 120,
    //   // maxWidth: 150,
    //   headerClassName: 'header',
    // },
  ];

  // const handlePopoverOpen = (event) => {
  //   const field = event.currentTarget.dataset.field;
  //   const id = event.currentTarget.parentElement.dataset.id;
  //   const row = rows.find((r) => r.id == id);
  //   setValue(row[field]);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };
  // const open = Boolean(anchorEl);

  return (
    <>
      <>
        <Paper sx={{ width: "100%" }}>
          <Box
            sx={{
              height: 615,
              width: 1,
              "& .MuiDataGrid-virtualScrollerRenderZone": {
                "& .MuiDataGrid-row": {
                  // backgroundColor: 'rgba(235, 235, 235, .7)',
                  fontWeight: 500,
                  color: "#667085",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                },
              },
              "& .header": {
                backgroundColor: "#F4F4F4",
                "& div": {
                  "& div": {
                    "& div": {
                      "& div": {
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "Inter, sans-serif",
                      },
                    },
                  },
                },
              },
            }}
          >
            <DataGrid
              sx={{
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
              }}
              // componentsProps={{
              //   cell: {
              //     onMouseEnter: handlePopoverOpen,
              //     onMouseLeave: handlePopoverClose,
              //   },
              // }}
              components={{
                LoadingOverlay: LinearProgress,
              }}
              rows={rows}
              columns={columns}
              loading={loading}
              rowHeight={72}
              disableColumnFilter={true}
              disableColumnMenu={true}
              pagination
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20, 50]}
              page={paginateTable.currentPage}
              rowCount={translist.count}
              pageSize={paginateTable.pageSize}
              onPageChange={(newPage) => handleChangePage(newPage)}
              onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
            />
            {/* <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1, borderRadius: '10px' }}>
                {value}
              </Typography>
            </Popover> */}
          </Box>
        </Paper>
      </>
    </>
  );
}
