import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { colors, handleBackgroundClick } from "../../utils/common";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useRecoveryWords } from "../../hooks/useRecoveryWords";
import { Grid, Stack, Typography, Button } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { generatePrivateKey } from "../../utils/hdKey";
import { getAccountFromPrivateKey } from "../../utils/flare";

const RecoverWalletModal = (props) => {
  const { open, handleClose, onAddressVerified } = props;

  const [showPhrase, setShowPhrase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inputWords, setInputWords] = useState([]);

  const onClose = (event, reason) => {
    handleBackgroundClick(reason, handleClose, false);
  };

  const { wallet_address } = useSelector((state) => state.profileReducer.user);

  const { randomWords, generateSeed } = useRecoveryWords();

  const onWordChange = (e, i) => {
    const { value } = e.target;
    if (value !== "") {
      if (value.includes(",")) {
        let wordsArray = value.split(",");
        setInputWords(wordsArray);
      } else {
        setInputWords([
          ...inputWords.slice(0, i),
          value,
          ...inputWords.slice(i + 1),
        ]);
      }
    }
  };

  const onRestoreWallet = async () => {
    setIsLoading(true);
    try {
      const seed = await generateSeed(inputWords[11], inputWords);

      if (seed !== undefined) {
        const keys = await generatePrivateKey(seed);
        console.log("kkey", keys);
        getAccountFromPrivateKey(
          keys,
          (wallet) => {
            console.log("generatedAddress", wallet);
            console.log("userAddress", wallet_address);
            if (wallet_address === wallet.address) {
              onAddressVerified(wallet);
            } else {
              toast.error("Invalid words or order");
            }
          },
          (error) => {
            setIsLoading(false);
            toast.error(error.message);
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Invalid words or order");
      console.log("onRestoreWallet#error", error.message);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <>
        <DialogTitle>Secret Recovery Phrase</DialogTitle>
        <DialogContent>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ lineHeight: "1 !important" }}>
              Access your wallet with your Secret Recovery Phrase.
            </Typography>
          </Stack>
          <Alert severity="info" sx={{ mb: 2 }}>
            You can paste your entire Secret Recovery Phrase into any field
          </Alert>
          <Grid container columnSpacing={4} rowSpacing={2}>
            {randomWords.map((word, index) => (
              <Grid item xs={6} sm={4} key={index} sx={{ padding: "0 10px" }}>
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  {index + 1}.{" "}
                  <input
                    type={showPhrase ? "text" : "password"}
                    onChange={(e) => onWordChange(e, index)}
                    value={inputWords[index]}
                    style={{
                      height: "32px",
                      width: "100%",
                      borderRadius: "20px",
                      border: "1px solid gray",
                      padding: "0 10px",
                      textAlign: "center",
                    }}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              component="label"
              sx={{ color: colors.primary, textTransform: "capitalize" }}
              startIcon={
                showPhrase ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              onClick={() => setShowPhrase(!showPhrase)}
            >
              {showPhrase ? "Hide Seed Phrase" : "Show Seed Phrase"}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            gap: 1,
          }}
        >
          <ButtonPrimary loading={isLoading} onClick={onRestoreWallet}>
            Continue
          </ButtonPrimary>
        </DialogActions>
      </>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(null, mapDispatchToProps)(RecoverWalletModal);
