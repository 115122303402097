import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col } from "@themesberg/react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader, Button } from "rsuite";
import { Card } from "react-bootstrap";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import {
  getProfile,
  setUserRole,
  setSingleWallet,
  setUserPayString,
} from "../../redux/actions/auth";
import { getBusinessDetails } from "../../redux/actions/businessApi";
import { Routes } from "../../routes";
import * as API from "../../ApiUrl";
import { MERCHANT_PROFILE } from "../../ApiUrl";
import * as actionTypes from "../../redux/actionTypes";
import mail from "../../assets/img/icons/mail.svg";
import stblogo from "../../assets/img/icons/stblogo.svg";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import { textFieldLabelProps } from "../../utils/common";
import { FormHelperText } from "@material-ui/core";
import RecoverWalletModal from "./RecoverWalletModal";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

function But({ isLoading, children, ...props }) {
  return (
    <button className="button" {...props}>
      {isLoading ? <Loader /> : children}
    </button>
  );
}

const Signin = ({
  setUserRole,
  setSingleWallet,
  changepage,
  setUserPayString,
  getBusinessDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [token, setToken] = useState(null);
  const [showRecoverWalletModal, setShowRecoverWalletModal] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = async (values) => {
    console.log(values);
    let userProfile;

    setLoading(true);

    try {
      const response = await axios.post(API.LOGIN_API, {
        ...values,
        token: captchaToken,
      });

      const token = await response.data;
      setToken(token);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
      };

      // Get user profile and store in redux
      try {
        const response = await axios.get(`${MERCHANT_PROFILE}profile/`, config);
        const { data } = response;
        userProfile = data.data;
        dispatch({ type: actionTypes.SET_PROFILE, payload: data.data });
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong");
      }

      // Get user stores and store in redux
      try {
        const response = await axios.get(
          `${API.STORE_LIST}?limit=1000`,
          config
        );

        console.log(response.data);
        if (response?.data?.results) {
          dispatch({
            type: actionTypes.SET_STORE_LIST,
            payload: response?.data?.results,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }

      // if(userProfile.wallet_address) {
      //   setShowRecoverWalletModal(true)
      // } else {
      continueSigninFlow(token);
      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const continueSigninFlow = async (tokenData = token) => {
    localStorage.setItem("user-info", JSON.stringify(tokenData));
    localStorage.setItem("token", tokenData.token);
    localStorage.setItem("user-role", tokenData.user_role);
    await setUserRole(tokenData.user_role);
    await setSingleWallet();
    await setUserPayString();
    await getBusinessDetails();
    toast.success("Login successful");
    history.replace("/");
  };

  const onCaptchaClick = async (value) => {
    setCaptchaToken(value);
    console.log(value);
    setIsCaptchaValid(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <main>
      <section className="vh-100 position-relative background">
        <img
          src={stblogo}
          className="position-absolute top-5 start-50 translate-middle"
          alt="logo"
        />

        <a
          className="socials twitter"
          href="https://twitter.com/Spend_The_Bits"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Spend_The_Bits
        </a>
        <a
          className="socials mail"
          href="mailto:help@spendthebits.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mail} alt="mail" />
          help@spendthebits.com
        </a>
        <Dialog
          open={true}
          disableEscapeKeyDown={true}
          fullWidth
          className="wallet-configure"
          BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0)" } }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "8px",
              width: "480px",
              minHeight: "428px",
              maxHeight: "80vh",
            },
          }}
        >
          <DialogTitle className="text-center">
            <p className="dialog-title fw-bolder">Sign in</p>
            <p>Welcome to Spend The Bits</p>
          </DialogTitle>
          <DialogContent className="merchant-wallet mt-4 pb-0">
            <Col xl={12} xs={12}>
              <TextField
                className="w-100 mb-4 mt-2"
                variant="outlined"
                name="email"
                id="email"
                label="Email"
                type="email"
                InputLabelProps={textFieldLabelProps}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Col>
            <Col xl={12} xs={12}>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className="w-100 pb-0 mb-0"
              >
                <InputLabel
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  htmlFor="password"
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  className="w-100"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputLabelProps={textFieldLabelProps}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormHelperText error>
                    {formik.errors.password}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Col>
          </DialogContent>
          <div
            className=" text-primary mb-1 fw-bolder"
            style={{ paddingRight: "1.5rem" }}
          >
            <Link
              to={Routes.ForgotPassword.path}
              className="text-end pull-right"
            >
              Forgot password?
            </Link>
          </div>
          <DialogActions className="captcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={onCaptchaClick}
            />
            <Button
              disabled={!formik.isValid || !isCaptchaValid}
              className={`btn action-button primary_color mt-0 text-white grow-1 w-100 mt-4 ${
                !formik.isValid || !isCaptchaValid ? "disabled" : null
              }`}
              loading={loading}
              onClick={() => {
                setIsButtonLoading(true);
                setTimeout(() => {
                  setIsButtonLoading(false);
                }, 2000);
                formik.handleSubmit();
              }}
            >
              Sign In
            </Button>
          </DialogActions>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <span className="fw-normal">
              Dont have an account?
              <Card.Link
                onClick={() => changepage("signup")}
                className="fw-bold text-primary"
              >
                {` Sign up `}
              </Card.Link>
            </span>
          </div>
        </Dialog>
        {/* {showRecoverWalletModal && (
        <RecoverWalletModal
          open={showRecoverWalletModal}
          handleClose={() => {
            setShowRecoverWalletModal(!showRecoverWalletModal)
          }}
          onAddressVerified={continueSigninFlow}
        />
      )} */}
      </section>
    </main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // explicitly forwarding arguments
    getprofile: () => dispatch(getProfile()),
    setUserRole: (val) => dispatch(setUserRole(val)),
    setSingleWallet: () => dispatch(setSingleWallet()),
    setUserPayString: () => dispatch(setUserPayString()),
    getBusinessDetails: () => dispatch(getBusinessDetails()),
  };
};
export default connect(null, mapDispatchToProps)(Signin);
