import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import documentImg from "../assets/img/icons/document.png";
const mime = require("mime-types");

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const numberCommaFormatter = (value) =>
  value.toLocaleString(undefined, { maximumFractionDigits: 8 });

export const percentCalculate = (current, prev) =>
  current > 0 ? ((current - prev) / current) * 100.0 : 0;

export const formatCurrency = (
  value = 0,
  decimal = 2,
  symbol = "",
  prefix = false
) => {
  let trimmedValue = Number(value).toFixed(decimal);
  return prefix ? `${symbol} ${trimmedValue}` : `${trimmedValue} ${symbol}`;
};

export const useStyles = makeStyles(() => ({
  textField: {
    // height: '20px',

    border: "#0B0523 1px solid",
    borderRadius: "8px",
  },
  input: {
    color: "black",
    backgroundColor: "white",
  },
}));

export const textFieldLabelProps = {
  style: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "gray",
  },
};

export const notificationTypesPastTenses = {
  refund: "Refunded",
  fund: "Funded",
  withdraw: "Withdrew",
  receipt: "Received",
};

export const statusColors = {
  colors: {
    complete: "#027A48",
    pending: "#003bbbdc",
    rejected: "rgb(124,15,48)",
    "application submitted": "#FCBD00",
  },
  bgColors: {
    complete: "#ECFDF3",
    pending: "#afe2ffdc",
    rejected: "#fca8c1",
    "application submitted": "#effca8",
  },
};

export const BACKDROP_CLICK = "backdropClick";

export const handleBackgroundClick = (
  reason,
  handleClose,
  allowCloseOnBackdrop
) => {
  if (reason && reason === BACKDROP_CLICK) {
    if (allowCloseOnBackdrop) {
      handleClose();
      return;
    }
    return;
  }
  handleClose();
};

export const colors = {
  primary: "#0b0423",
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const numricRegex = /(?=.*[0-9])/;

export const removeCodeFromPhone = (phone, code) => {
  if (_.startsWith(phone, code)) {
    return _.join(_.slice(phone, code?.length), "");
  } else {
    console.error("Error: Code not found at the beginning of the phone string");
    return phone;
  }
};

export const allowedMediaTypes = {
  "image/*": [],
  "video/*": [],
  "application/*": [
    ".pdf",
    ".msword",
    ".xlsx",
    ".xls",
    ".rtf",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
  ],
  "text/*": [],
};

export const getThumbnail = (url) => {
  console.log(mime.lookup(url), url);
  if (url) {
    return mime.lookup(url)?.split("/")[0] === "image" ? url : documentImg;
  } else {
    return documentImg;
  }
};

export const urlRegex =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
