export const Routes = {
  // pages
  Signin: { path: "/signin" },
  DashboardOverview: { path: "/" },
  // DashboardOverview: { path: "/dashboard/dashboardoverview" },
  Settings: { path: "/settings" },
  Menu: { path: "/merchantprofile/wallets" },
  Statement: { path: "/merchantprofile/statement" },
  Withdrawl: { path: "/merchantprofile/withdrawl" },
  // Reports
  Reports: { path: "/reports" },
  Transaction: { path: "/reports/transaction" },

  // Transactions
  Transactions: { path: "/transactions" },
  Balance: { path: "/transactions/balance" },
  ReportTransactions: { path: "/transactions/reporttransactions" },

  History: { path: "/transaction/history" },
  SystemUser: { path: "/administration/systemUser" },
  StoreMenu: { path: "/administration/store" },
  CounterMenu: { path: "/administration/counter/:id" },
  UserApproval: { path: "/administration/userApproval/:id" },
  AddSystemUser: { path: "/administration/addSystemUser" },
  BusinessDetails: { path: "/administration/updatebusinessdetails" },
  BusinessDetailsEdit: { path: "/kycdone" },
  Invoices: { path: "/invoices" },
  Support: { path: "/support" },
  Customer: { path: "/customer" },
  CustomerDetails: { path: "/customerdetails" },
  NewTicket: { path: "/support/newticket" },
  TicketReply: { path: "/support/ticketreply/:id" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  // Billing: { path: "/examples/billing" },
  // Invoice: { path: "/examples/invoice" },
  Signup: { path: "/stb/sign-up" },
  Signupthanks: { path: "/signupthanks" },
  ForgotPassword: { path: "/stb/forgot-password" },
  ChangePassword: { path: "/stb/change-password" },
  ResetPassword: { path: "/stb/reset-password" },
  Thankyou: { path: "/stb/thankyou" },
  Lock: { path: "/stb/lock" },
  NotFound: { path: "/stb/404" },
  ServerError: { path: "/stb/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
  UserChat: { path: "/support/ticket/:conversationKey" },
  UserChatGeneral: { path: "/support/customer/" },
};
