import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import { totalOrders } from "../../data/charts";
import { TOTAL_RECEIPTS_LIST } from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { percentCalculate } from "../../utils/common";
import moment from "moment-timezone";

const TotalReceipt = (props) => {
    const { currentMonth, lastMonth } = props;
    const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const series = totalOrders.map(d => d.value);

    const [currentMonthTotalReceiptsList, setCurrentMonthTotalReceiptsList] = useState(null)
    const [lastMonthTotalReceiptsList, setLastMonthTotalReceiptsList] = useState(null)
    const [currentMonthText, setCurrentMonthText] = useState(null);
    const [lastMonthText, setLastMonthText] = useState(null);

    useEffect(() => {
        getTotalReceiptsList();
    }, [])

    const getTotalReceiptsList = () => {
        merchantApi.get(TOTAL_RECEIPTS_LIST, currentMonth).then(response => {
            const { data } = response;
            setCurrentMonthTotalReceiptsList(data.data);
            setCurrentMonthText(moment(data.data.to_date).format('MMMM'))
        }).catch(error => {
        })

        merchantApi.get(TOTAL_RECEIPTS_LIST, lastMonth).then(response => {
            const { data } = response;
            setLastMonthTotalReceiptsList(data.data);
            setLastMonthText(moment(data.data.to_date).format('MMMM'))
        }).catch(error => {
        })
    };

    const getPercentValue = () => {
        const percentage = percentCalculate(currentMonthTotalReceiptsList.receipt, lastMonthTotalReceiptsList.receipt);
        const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
        const percentageColor = percentage < 0 ? "text-danger" : "text-success";
        return <small className="mt-2">
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={`${percentageColor} fw-bold`}>
                {percentage}%
            </span>
        </small>
    }

    const BarChart = (props) => {
        const { labels = [], series = [], chartClassName = "ct-golden-section" } = props;
        const data = { labels, series };

        const options = {
            low: 0,
            showArea: true,
            axisX: {
                position: 'end'
            },
            axisY: {
                showGrid: false,
                showLabel: false,
                offset: 0
            }
        };

        const plugins = [
            ChartistTooltip()
        ]

        return (
            <Chartist data={data} options={{ ...options, plugins }} type="Bar" className={chartClassName} />
        );
    };

    return (
        <Card border="light" className="shadow-sm">
            {
                currentMonthTotalReceiptsList && lastMonthTotalReceiptsList &&
                <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
                    <div className="d-block">
                        <h6 className="fw-normal text-gray mb-2">Total Receipt</h6>
                        <h3>{currentMonthTotalReceiptsList.receipt}</h3>
                        {getPercentValue()}
                    </div>
                    <div className="d-block ms-auto">
                        <div className="d-flex align-items-center text-end mb-2">
                            <span className={`shape-xs rounded-circle bg-primary me-2`} />
                            <small className="fw-normal">{currentMonthText}</small>
                        </div>
                        <div className="d-flex align-items-center text-end mb-2">
                            <span className={`shape-xs rounded-circle bg-secondary me-2`} />
                            <small className="fw-normal">{lastMonthText}</small>
                        </div>
                    </div>
                </Card.Body>
            }
            <Card.Body className="p-2">
                <BarChart labels={labels} series={series} />
            </Card.Body>
        </Card>
    );
};

export default TotalReceipt;