export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

//KYB

export const GET_KYB_STATUS = "GET_KYB_STATUS";
export const SET_KYB_STATUS = "SET_KYB_STATUS";
export const GET_KYB_STATUS_FAIL = "GET_KYB_STATUS_FAIL";
export const SET_KYB_FORM_DATA = "SET_KYB_FORM_DATA";

export const GET_BUSINESSTYPE = "GET_BUSINESSTYPE";
export const SET_BUSINESSTYPE = "SET_BUSINESSTYPE";
export const GET_BUSINESSCATEGORY = "GET_BUSINESSCATEGORY";
export const SET_BUSINESSCATEGORY = "SET_BUSINESSCATEGORY";
export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";

export const GET_STORE_LIST = "GET_STORE_LIST";
export const SET_STORE_LIST = "SET_STORE_LIST";

// User
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_IS_SINGLE_WALLET = "SET_IS_SINGLE_WALLET";
export const SET_USER_PAYSTRING = "SET_USER_PAYSTRING";

export const CLEAR_STORE_DATA = "CLEAR_STORE_DATA";
export const SET_WALLET = "SET_WALLET";
export const COMPLETE_FORM = "COMPLETE_FORM";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const RESET_LOADING = "RESET_LOADING";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const SET_LOADING = "SET_LOADING";
export const SET_TICKETS = "SET_TICKETS";
export const SUPPORT_TICKET = "SUPPORT_TICKET";
