//export const BASE_URL = "https://dev2api.spendthebits.com";
// export const BASE_URL = 'https://devapi.spendthebits.com';
// export const BASE_URL = 'https://testapi.spendthebits.com';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = `${BASE_URL}/v1`;
const BASE_URL_MERCHANT = `${VERSION}/merchant`;
export const LOGIN_API = BASE_URL_MERCHANT + "/user/login/";
export const SIGNUP_API = BASE_URL_MERCHANT + "/user/register/";
export const ADD_MERCHANT_API = BASE_URL_MERCHANT + "/business/";
export const CONFIGURE_API = BASE_URL_MERCHANT + "/configure/";
export const TERMS_CONDITION = "https://spendthebits.com/terms-conditions/";
export const PRIVACY_POLICY = "https://spendthebits.com/privacy-policy/";
export const XRPL_TEST_ACCOUNT_CREATE_URL =
  "https://faucet.altnet.rippletest.net/accounts";
// export const XRPL_TEST_ACCOUNT_CREATE_URL =
//   "https://s.altnet.rippletest.net:51234";

export const ROLES = BASE_URL_MERCHANT + "/role/";

export const COMPANY_ROLE = BASE_URL_MERCHANT + "/company_role/";

//Revenue
export const GET_REVENUE = BASE_URL_MERCHANT + "/report/revenue";
export const GET_SALES = BASE_URL_MERCHANT + "/report/sales/";
export const GROUP_BY_RECEIPT = BASE_URL_MERCHANT + "/report/group_by_receipt/";
export const GET_CUSTOMER_REPORT = BASE_URL_MERCHANT + "/report/customers/";
export const GET_MEMBERS_REPORT =
  BASE_URL_MERCHANT + "/report/members/size_history/";
// https://devapi.spendthebits.com/v1/merchant/report/members/size_history/
// https://devapi.spendthebits.com/v1/merchant/report/customers/
// https://devapi.spendthebits.com/v1/merchant/report/group_by_receipt/
// https://devapi.spendthebits.com/v1/merchant/report/sales/
// https://devapi.spendthebits.com/v1/merchant/report/revenue/?group_by=day

// Occupation
export const OCCUPATION_LIST = VERSION + "/occupations/";

// Resend password mail
export const RESEND_PASSWORD_MAIL =
  BASE_URL_MERCHANT + "/user/resend_password_mail/";

//User
export const MERCHANT_PROFILE = BASE_URL_MERCHANT + "/user/";
export const USER_PROFILE = BASE_URL_MERCHANT + "/account/user_profile/";
export const BUSINESS_TYPE_API = BASE_URL_MERCHANT + "/business_type/";
export const CATEGORY_API = BASE_URL_MERCHANT + "/category/";
export const UPLOAD_DOCUMENT = BASE_URL_MERCHANT + "​/account/business/";
export const GET_DOCUMET_TYPE = BASE_URL_MERCHANT + "/kyb/doc_type/";
export const GET_PROFILE = BASE_URL_MERCHANT + "/account/user_profile/";
export const COUNTRY_LIST = `${VERSION}/countries/`;
export const CATEGORY_LIST = `${BASE_URL_MERCHANT}/category/`;
export const RESENT_ACCOUNT_ACTIVATION_EMAIL = `${BASE_URL_MERCHANT}/user/resend_password_mail/`;
export const GET_DOC_TYPES = VERSION + "/user/kyc/doc_type/";

//kyb
export const KYB_START = BASE_URL_MERCHANT + "/kyb/";
export const KYB_STATUS = BASE_URL_MERCHANT + "/kyb/";
export const UPLOAD_KYB_DOC = BASE_URL_MERCHANT + "/kyb/";
export const INITIATE_KYB = BASE_URL_MERCHANT + "/kyb/start/";
export const UPDATE_CURRENT_STEP =
  BASE_URL_MERCHANT + "/kyb/update_current_step/";
export const KYB_FINAL_SUBMIT = BASE_URL_MERCHANT + "/kyb/final_submit/";
export const PERSONAL_KYC_DOC_TYPES = VERSION + "/user/kyc/doc_type/";
export const SHAREHOLDER = BASE_URL_MERCHANT + "/shareholder/";
export const SHAREHOLDER_KYC = SHAREHOLDER + "kyc/";

//TRANSACTIONS
export const FUND_TRANSACTION =
  BASE_URL_MERCHANT + "/transaction/fund_transactions/";
export const GET_TRANSACTION = VERSION + "/transaction/";
export const EXPORT_EXCEL = GET_TRANSACTION + "export/";

// https://devapi.spendthebits.com/v1/transaction/export/

// https://devapi.spendthebits.com/v1/transaction/?type=receipt
export const RESEND_OTP = BASE_URL_MERCHANT + "/transaction/resend_otp/";
export const VERIFY_OTP = VERSION + "/transaction/verify_otp/";

// SystemUser
export const ADD_USER = BASE_URL_MERCHANT + "/user/";
export const USER_LIST = BASE_URL_MERCHANT + "/user/";
export const USER_ROLE = BASE_URL_MERCHANT + "/user/roles/";

// Transaction
export const TRANSACTION_LIST = VERSION + "/transaction/";
export const COIN = BASE_URL_MERCHANT + "/coin/";
export const TRANS_STORE = VERSION + "/transaction/stores/";
export const TRANS_STATUS = VERSION + "/transaction/status/";
export const TRANSACTION_TYPE = VERSION + "/transaction/type/";
export const TRANSACTION_APPROVE_REJECT =
  VERSION + "/transaction/approve_or_decline/";
export const TRANSACTION_VERIFY_WITHDRAW_ADDRESS =
  VERSION + "/transaction/verify_withdraw_address/";
export const RESEND_REFUND_OTP = TRANSACTION_LIST + "resend_otp/";
// https://devapi.spendthebits.com/v1/transaction/resend_otp/

export const TRANSACTION_GRAPH_DATA = VERSION + "/transaction/graph_data/";
// https://devapi.spendthebits.com/v1/transaction/graph_data/

export const GET_COINS = VERSION + "/coin";

// Change Password
export const FORGOT_PASSWORD = VERSION + "/user/password_reset/";
export const RESET_PASSWORD = BASE_URL_MERCHANT + "/user/change_password/";
export const RESET_NEW_PASSWORD = BASE_URL_MERCHANT + "/user/reset_password/";

//// STORE
export const STORE_LIST = BASE_URL_MERCHANT + "/store/";
export const COUNTER = BASE_URL_MERCHANT + "/store/counter/";

// support
export const SUPPORT_CATEGORY = BASE_URL_MERCHANT + "/support/category/";
export const SUPPORT_STATUS = BASE_URL_MERCHANT + "/support/status/";
export const TICKET = BASE_URL_MERCHANT + "/support/ticket/";
export const TICKET_LIST = BASE_URL_MERCHANT + "/support/ticket/";
export const TICKET_REPLY = BASE_URL_MERCHANT + "/support/ticket/reply/";
export const GET_TICKET_REPLY = BASE_URL_MERCHANT + "/support/ticket/reply/";
export const GET_SUPPORT = BASE_URL_MERCHANT + "/support/ticket/";
export const TICKET_STATUS = BASE_URL_MERCHANT + "/support/ticket/";
export const TICKET_PRIORITY = BASE_URL_MERCHANT + "/support/priority/";

//Dashboard
export const CHART_DATA = BASE_URL_MERCHANT + "/report/revenue/";
export const REVENUE = BASE_URL_MERCHANT + "/report/revenue/";
export const CUSTOMERS_IN_PERIOD =
  BASE_URL_MERCHANT + "/report/customer_in_period/";
export const TEAM_MEMBERS_LIST = `${BASE_URL_MERCHANT}/report/members/`;
export const TOTAL_RECEIPTS_LIST = `${BASE_URL_MERCHANT}/report/total_receipt/`;

//Config
export const CONFIG = BASE_URL_MERCHANT + "/config/";
export const READINESS = BASE_URL_MERCHANT + "/readiness/";

// Paystring
export const PAY_STRING = BASE_URL_MERCHANT + "/paystring/";
export const PAYSTRING_DOMAIN = VERSION + "/paystring/domain/";

//SecretKey
export const GET_PASS_PHRASE = VERSION + "/wallet/passphrase/";
export const GET_WALLET = VERSION + "/wallet/";
export const GET_WALLET_ISSUER = VERSION + "/wallet/issuer_address/";
export const GET_MERCHENT_WALLET_LIST = BASE_URL_MERCHANT + "/wallet";
export const TRUST_LINE = VERSION + "/wallet/trust_line/";

// Balance
export const GET_BALANCE = BASE_URL_MERCHANT + "/balance/";
export const GET_BALANCE_GRAPH_DATA = GET_BALANCE + "history/";

// https://devapi.spendthebits.com/v1/merchant/balance/history/

// Merchant
export const CHECK_SINGLE_WALLET = BASE_URL_MERCHANT + "/config/";

// Fund wallet
// https://devapi.spendthebits.com/v1/transaction/pay/
export const INITIAL_FUND = VERSION + "/transaction/pay/";
export const VERIFY_CAPTCHA = BASE_URL_MERCHANT + "/user/verify_captcha/";
export const TIMEZONES = BASE_URL + "/timezones/";
export const GET_FUND_ADDRESS =
  VERSION + "/wallet/wallet_deposit_blockchain_address/";
export const DEPOSIT = VERSION + "/fund/deposit/";
export const WITHDRAW = VERSION + "/withdraw/";
export const WITHDRAW_FEE = WITHDRAW + "fee";

export const FILE_UPLOAD = VERSION + "/file/";
export const MERCHANT_IDENTOMAT_KYC = `${VERSION}/kyc/start_sdk_kyc/`;
export const MERCHANT_IDENTOMAT_KYC_SUBMIT = `${VERSION}/kyc/submit_sdk_kyc/`;
export const VERIFY_PASSWORD = `${BASE_URL_MERCHANT}/user/verify_password/`;
export const CALCULATE_COMMISSION = `${VERSION}/transaction/calculate_commission/`;

//
