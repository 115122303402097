import React, { useEffect, useState } from "react";

import { Box, Grid, Stack, Typography, TextField, Paper } from "@mui/material";
import PreviewItem from "../../../../components/preview/PreviewItem";
import PreviewContainer from "../../../../components/preview/PreviewContainer";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  completeForm,
  getBusinessDocs,
  getBusinessInfo,
  getCompanyRoles,
  getPersonalDocs,
  getPersonalInfo,
  getPersonalKycDocTypes,
  getShareholdersList,
  getStatus,
  initateVerification,
} from "../../../../redux/actions/kybActions";
import { toast } from "react-toastify";
import { styled } from "@material-ui/core/styles";
import Colors from "../../../../assets/colors";
import Image from "../../../../components/image";
import _ from "lodash";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import { useHistory } from "react-router-dom";
import SuccessfullKyb from "../../../../components/model/SuccessfullKyb";

const Preview = ({ onCompleteFlow, handleBack, updateCurrentStepHandler }) => {
  const [loading, setLoading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [personalDocs, setPersonalDocs] = useState(null);
  const [shareholdersList, setShareholdersList] = useState([]);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [businessDocs, setBusinessDocs] = useState(null);
  const [roles, setRoles] = useState(null);
  const [docsList, setDocsList] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);

  const {
    shareholder: { id: shareholderId },
    email: userEmail,
  } = useSelector((state) => state.profileReducer.user);
  const countryList = JSON.parse(localStorage.getItem("country-list")) || [];
  const categoryList = JSON.parse(localStorage.getItem("category-list")) || [];
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    // Get personal info
    dispatch(
      getPersonalInfo(
        shareholderId,
        (res) => {
          setPersonalInfo(res);
        },
        (err) => console.log("xwex23x23x", err)
      )
    );

    // Get personal docs
    dispatch(
      getPersonalDocs(
        (res) => {
          console.log("csdcsoop", res);
          const data = res?.[0];
          setPersonalDocs(data);
        },
        (err) => {
          console.log("pmcopwec", err);
        }
      )
    );

    // Get shareholders list
    dispatch(
      getShareholdersList(
        (res) => {
          console.log("cacasdsdsc", res, userEmail);
          if (res.length < 2) {
            setShareholdersList([]);
          } else {
            const listWithoutCurrentUser = res.filter(
              (item) => item.email !== userEmail
            );

            // const dateConvertedList = listWithoutCurrentUser.map((item) => {
            //   return {...item, dob: new Date(item.dob)}
            // })
            setShareholdersList(listWithoutCurrentUser);
          }
        },
        (err) => {
          console.log("cweec32dc", err);
        }
      )
    );

    // Get business info
    dispatch(
      getBusinessInfo(
        (res) => {
          console.log("reacascasc", res);
          const province = countryList
            .filter(
              (country) =>
                country.alpha2code?.toLowerCase() ===
                res?.country?.alpha2code?.toLowerCase()
            )[0]
            .province.find((province) => province.id === res.province);

          const category = categoryList.find(
            (category) => category.id === res.category.parent_category
          );

          setBusinessInfo({
            ...res,
            province,
            subCategory: res.category.name,
            category: category.name,
          });
        },
        (err) => console.log("xwex23x23x", err)
      )
    );

    // Get Business docs
    dispatch(
      getBusinessDocs(
        (res) => {
          console.log("cdsecwec", res);

          const docs = res.data.documents;

          const filteredDocs = docs.filter((doc) => doc.document_type);
          setBusinessDocs(filteredDocs);
        },
        (err) => console.log("acacecwc", err)
      )
    );
    dispatch(
      getCompanyRoles(
        (res) => {
          console.log("wecwcewecwec", res);
          setRoles(res);
        },
        (err) => {
          console.log("err");
        }
      )
    );

    dispatch(
      getPersonalKycDocTypes(
        (res) => {
          console.log("csdcasdssoop", res);
          setDocsList(res.data.doc_types);
        },
        (err) => {
          console.log("pmcopwec", err);
        }
      )
    );
    setLoading(false);
  }, []);

  const handleSubmit = () => {
    setSubmitLoading(true);
    dispatch(
      initateVerification(
        (res) => {
          dispatch(completeForm());
          updateCurrentStepHandler(5);
          toast.success("KYC & KYB submitted successfully!");
          setSubmitLoading(false);
          setSuccessSubmit(true);
          // history.replace("/");
        },
        (err) => {
          setSubmitLoading(false);
          toast.error("KYC & KYB submission failed! Please try again later.");
        }
      )
    );
  };

  const personalInfoContent = (
    <>
      <PreviewItem title={"First Name"} content={personalInfo?.first_name} />
      <PreviewItem title={"Last Name"} content={personalInfo?.last_name} />
      <PreviewItem title={"Date of Birth"} content={personalInfo?.dob} />
      <PreviewItem
        title={"Phone Number"}
        content={personalInfo?.phone_number}
      />
      <PreviewItem
        title={"Politically Exposed Person"}
        content={personalInfo?.pep ? "Yes" : "No"}
      />
      <PreviewItem title={"Country"} content={personalInfo?.country?.name} />
      <PreviewItem title={"Province"} content={personalInfo?.province.name} />
      <PreviewItem title={"City"} content={personalInfo?.city} />
      <PreviewItem title={"Postal Code"} content={personalInfo?.postal_code} />
      <PreviewItem title={"Address"} content={personalInfo?.address} />
    </>
  );

  const shareholderContent = (
    <>
      {shareholdersList.map((shareholder, i) => (
        <PreviewContainer
          title={_.startCase(
            _.toLower(`${shareholder?.first_name} ${shareholder?.last_name}`)
          )}
        >
          <PreviewItem title={"Date of Birth"} content={shareholder?.dob} />
          <PreviewItem
            title={"Phone Number"}
            content={shareholder?.phone_number}
          />
          <PreviewItem title={"email"} content={shareholder?.email} />
          <PreviewItem
            title={"Ownership"}
            content={`${shareholder?.ownership} %`}
          />
        </PreviewContainer>
      ))}
    </>
  );

  console.log("cwcsdcsx23d23xd", businessDocs);
  const role = roles?.find((role) => role.id === businessInfo?.role)?.name;
  const businessInfoContent = (
    <>
      <PreviewItem title={"Business Name"} content={businessInfo?.name} />
      <PreviewItem
        title={"No. of Stores"}
        content={businessInfo?.no_of_store}
      />
      <PreviewItem
        title={"Business Category"}
        content={businessInfo?.category}
      />
      <PreviewItem
        title={"Business Sub-category"}
        content={businessInfo?.subCategory}
      />
      <PreviewItem
        title={"Occupation Type"}
        content={businessInfo?.occupation}
      />
      <PreviewItem title={"Country"} content={businessInfo?.country?.name} />
      <PreviewItem title={"Province"} content={businessInfo?.province?.name} />
      <PreviewItem title={"City"} content={businessInfo?.city} />
      <PreviewItem title={"Postal Code"} content={businessInfo?.postal_code} />
      <PreviewItem title={"Address"} content={businessInfo?.address} />
      <PreviewItem title={"Timezone"} content={businessInfo?.timezone} />
      <PreviewItem
        title={"Company Type"}
        content={businessInfo?.business_type?.option}
      />
      <PreviewItem title={"Role in Company"} content={role} />
      <PreviewItem
        title={"Year of Incorporation"}
        content={businessInfo?.incorporation_year}
      />
      <PreviewItem
        title={"Business Registration Number"}
        content={businessInfo?.registration_no}
      />
      <PreviewItem title={"Business Website"} content={businessInfo?.web_url} />
    </>
  );

  const docName = docsList?.find(
    (doc) => doc.value === personalDocs?.document_type
  )?.name;

  return (
    <>
      <Stack sx={{ width: "100%", flexDirection: "column", gap: 3 }}>
        <PreviewContainer title={"Personal Information"}>
          {personalInfoContent}
        </PreviewContainer>
        {/* <PreviewContainer title={"Personal Documents"}>
          <Stack direction={"column"}>
            <Typography>{docName}</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              <Box
                key={personalDocs?.front_part_of_document}
                sx={{ px: 0.75, textAlign: "center" }}
              >
                <Image
                  disabledEffect
                  alt="thumb image"
                  src={personalDocs?.front_part_of_document}
                  sx={{
                    width: "200px",
                    height: "200px",
                    borderRadius: 1.5,
                    cursor: "pointer",
                  }}
                />
                <Typography variant="body2">Front Side</Typography>
              </Box>
              <Box
                key={personalDocs?.back_part_of_document}
                sx={{ px: 0.75, textAlign: "center" }}
              >
                <Image
                  disabledEffect
                  alt="thumb image"
                  src={personalDocs?.back_part_of_document}
                  sx={{
                    width: "200px",
                    height: "200px",
                    borderRadius: 1.5,
                    cursor: "pointer",
                  }}
                />
                <Typography variant="body2">Back Side</Typography>
              </Box>
            </Stack>
          </Stack>
        </PreviewContainer> */}

        {shareholdersList?.length > 0 && (
          <PreviewContainer title={"Shareholders"}>
            <Stack direction={"column"} spacing={2}>
              {shareholderContent}
            </Stack>
          </PreviewContainer>
        )}

        <PreviewContainer title={"Business Information"}>
          {businessInfoContent}
        </PreviewContainer>

        <PreviewContainer title={"Business Documents"}>
          <Stack direction={"column"}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              {businessDocs?.map((doc) => (
                <Box key={doc?.document} sx={{ px: 0.75, textAlign: "center" }}>
                  <Image
                    disabledEffect
                    alt="thumb image"
                    src={doc?.document}
                    sx={{
                      width: "200px",
                      height: "200px",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                  <Typography variant="body2">
                    {_.startCase(doc?.document_type)}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        </PreviewContainer>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "2rem",
            gap: 1,
          }}
        >
          <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>

          <ButtonPrimary onClick={handleSubmit} loading={submitLoading}>
            Confirm and Submit
          </ButtonPrimary>
        </Stack>
      </Stack>
      <SuccessfullKyb isModalOpen={successSubmit} />
    </>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(Preview);
