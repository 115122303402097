import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  CircularProgress,
  Box,
  AppBar,
  Typography,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";

import { merchantApi } from "../redux/api/apiConfig";
import { ADD_MERCHANT_API, RESET_NEW_PASSWORD } from "../ApiUrl";
import MerchantWalletAddress from "../components/model/MerchantWalletAddress";
import * as API from "../ApiUrl";
import Caution from "../assets/img/caution.svg";
import MessageModal from "../components/MessageModal";
import { useSelector } from "react-redux";
import PreviewItem from "../components/preview/PreviewItem";
import PreviewContainer from "../components/preview/PreviewContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const validationSchema = yup.object({
  old_password: yup
    .string("Enter your old password")
    .matches(
      passwordRegex,
      "Please enter mix of uppercase, lowercase, special character & number"
    )
    .min(8, "Old Password should be of minimum 8 characters length")
    .required("Old password is required"),
  new_password: yup
    .string("Enter your new password")
    .matches(
      passwordRegex,
      "Please enter mix of uppercase, lowercase, special character & number"
    )
    .min(8, "New Password should be of minimum 8 characters length")
    .required("New password is required"),

  confirmpassword: yup
    .string("Enter your confirm password")
    .oneOf([yup.ref("new_password")], "Password does not match")
    .required("Confirm Password is Required"),
});

export const GeneralInfo = ({ userProfileData }) => {
  const [duration, setDuration] = React.useState("");

  const [registration, setRegistrationdate] = React.useState("");
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [currentMerchantCategory, setCurrentMerchantCategory] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = React.useState(
    userProfileData.user_role === "Cashier" ? 1 : 0
  );
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [isMerchantWalletAddressModel, setisMerchantWalletAddressModel] =
    useState(false);
  const [isWalletTrustlineModel, setisWalletTrustlineModel] = useState(false);
  // const [isFunded, setIsFunded] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState({
    symbol: "",
    decimalPlace: 2,
    symbolPrefix: false,
  });

  const { permissions, userRole } = useSelector((state) => state?.authReducer);

  const history = useHistory();

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const countryList = JSON.parse(localStorage.getItem("country-list"));

  const country = countryList?.filter(
    (country) => country.id === merchantDetail?.country
  );

  useEffect(() => {
    getMerchantDetail();
    // getWalletDetails();
    getCurrency();
  }, []);

  useEffect(() => {
    if (userProfileData) {
      getMerchantCategory();
    }
  }, [userProfileData]);

  const getCurrency = async () => {
    const response = await merchantApi.get(API.GET_BALANCE, config);
    setUserCurrency({
      symbol: response.data.data.currency.symbol,
      decimalPlace: response.data.data.currency.decimal_place,
      symbolPrefix: response.data.data.currency.symbol_prefix,
    });
  };

  const getMerchantDetail = () => {
    merchantApi
      .get(ADD_MERCHANT_API)
      .then((response) => {
        setMerchantDetail(response.data);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const getMerchantCategory = () => {
    merchantApi
      .get(API.CATEGORY_API)
      .then((response) => {
        setCurrentMerchantCategory(
          response?.data?.data?.filter(
            (category) =>
              category?.id ===
              userProfileData?.merchant?.category?.parent_category
          )
        );
      })
      .catch((error) => {
        console.log("merchant category error", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = (data) => {
    setloading(true);
    merchantApi
      .post(RESET_NEW_PASSWORD, data)
      .then((response) => {
        const { data } = response;
        const Toast = Swal.mixin({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Password Change successfully",
        });
        history.push("/signin");
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        setError(error.data.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirmpassword: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const setTrustLineClicked = () => {
    history.push("/merchantprofile/wallets");
  };

  const fundWalletClicked = () => {
    togglePayModal();
  };

  const toggleMessageModal = () => {
    setShowMessageModal((prevState) => !prevState);
  };

  const togglePayModal = () => {
    setShowPaymentModal((prevState) => !prevState);
  };

  const personalInfoContent = (
    <>
      <PreviewItem title={"First Name"} content={userProfileData?.first_name} />
      <PreviewItem title={"Last Name"} content={userProfileData?.last_name} />
      <PreviewItem title={"Date of Birth"} content={userProfileData?.dob} />
      <PreviewItem
        title={"Phone Number"}
        content={userProfileData?.phone_number}
      />
      <PreviewItem
        title={"Politically Exposed Person"}
        content={userProfileData?.pep ? "Yes" : "No"}
      />
      <PreviewItem title={"Country"} content={userProfileData?.country?.name} />
      <PreviewItem
        title={"Province"}
        content={userProfileData?.province?.display_name}
      />
      <PreviewItem title={"City"} content={userProfileData?.city} />
      <PreviewItem
        title={"Postal Code"}
        content={userProfileData?.postal_code}
      />
      <PreviewItem title={"Address"} content={userProfileData?.address} />
    </>
  );

  const businessInfoContent = (
    <>
      <PreviewItem
        title={"Business Name"}
        content={userProfileData?.merchant?.name}
      />
      <PreviewItem
        title={"No. of Stores"}
        content={userProfileData?.merchant?.no_of_store}
      />
      {currentMerchantCategory && (
        <PreviewItem
          title={"Business Category"}
          content={currentMerchantCategory[0].name}
        />
      )}
      <PreviewItem
        title={"Business Sub-category"}
        content={userProfileData?.merchant?.category?.name}
      />
      <PreviewItem
        title={"Occupation Type"}
        content={userProfileData?.merchant?.occupation}
      />
      <PreviewItem
        title={"Country"}
        content={userProfileData?.merchant?.country?.name}
      />
      <PreviewItem
        title={"Province"}
        content={userProfileData?.merchant?.province?.name}
      />
      <PreviewItem title={"City"} content={userProfileData?.merchant?.city} />
      <PreviewItem
        title={"Postal Code"}
        content={userProfileData?.merchant?.postal_code}
      />
      <PreviewItem
        title={"Address"}
        content={userProfileData?.merchant?.address}
      />
      <PreviewItem
        title={"Timezone"}
        content={userProfileData?.merchant?.name}
      />
      <PreviewItem
        title={"Company Type"}
        content={userProfileData?.merchant?.business_type?.option}
      />
      {/* <PreviewItem title={"Role in Company"} content={role} /> */}
      <PreviewItem
        title={"Year of Incorporation"}
        content={userProfileData?.merchant?.incorporation_year}
      />
      <PreviewItem
        title={"Business Registration Number"}
        content={userProfileData?.merchant?.registration_no}
      />
      <PreviewItem
        title={"Business Website"}
        content={userProfileData?.merchant?.web_url}
      />
      <PreviewItem
        title={"KYB Status"}
        content={userProfileData?.merchant?.kyb_status}
      />
    </>
  );

  return (
    <>
      <div className="page-wrapper mt-2">
        <div className="tabing">
          <div className="reports  bg-white p-xl-4 p-0 radi">
            <div>
              <AppBar position="static" className="bg-white text-dark">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0b0523",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs"
                  centered
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Password" {...a11yProps(1)} />
                  {/* <Tab label="Payment" {...a11yProps(2)} />
                  <Tab label="Security" {...a11yProps(3)} /> */}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Row className=" mt-4 gap-2 space-between">
                  <Col xs={12} md={12} lg={12}>
                    <PreviewContainer title={"Personal Information"}>
                      {personalInfoContent}
                    </PreviewContainer>
                  </Col>
                  <Col xs={12} md={12} lg={12} style={{ marginTop: "20px" }}>
                    <PreviewContainer title={"Business Information"}>
                      {businessInfoContent}
                    </PreviewContainer>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Row className=" mt-4">
                  <Col xl={12}>
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="old_password"
                              name="old_password"
                              label="Old Password"
                              type="password"
                              value={formik.values.old_password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.old_password &&
                                Boolean(formik.errors.old_password)
                              }
                              helperText={
                                formik.touched.old_password &&
                                formik.errors.old_password
                              }
                            />
                          </div>
                        </Col>
                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="new_password"
                              name="new_password"
                              label="New Password"
                              type="password"
                              value={formik.values.new_password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.new_password &&
                                Boolean(formik.errors.new_password)
                              }
                              helperText={
                                formik.touched.new_password &&
                                formik.errors.new_password
                              }
                            />
                          </div>
                        </Col>

                        <Col xl={4} sm={4} xs={12}>
                          <div class="form-floating mb-2 ">
                            <TextField
                              fullWidth
                              variant="outlined"
                              className="mt-4 bg-white"
                              id="confirmpassword"
                              name="confirmpassword"
                              label="Confirm Password"
                              type="password"
                              value={formik.values.confirmpassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.confirmpassword &&
                                Boolean(formik.errors.confirmpassword)
                              }
                              helperText={
                                formik.touched.confirmpassword &&
                                formik.errors.confirmpassword
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      {error && (
                        <div className=" mb-2  text-danger">
                          <span className="fw-normal">{error}</span>
                        </div>
                      )}
                      <Col xl={12}>
                        <Button
                          disabled={!formik.isValid || loading}
                          appearance="primary"
                          type="submit"
                          className="mt-3 primary_color font-fam font-12 ms-2 pe-4 ps-4"
                        >
                          {loading && <CircularProgress size={14} />}
                          {!loading && "Update"}
                        </Button>
                      </Col>
                    </form>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel value={value} index={2}>
                Payment Method
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Col xl={4}>
                  <Card.Body className="d-flex mt-xl-4 mt-0 align-items-start">
                    <Card.Img
                      src={Caution}
                      alt="Caution Sign"
                      className="w-50px me-2"
                    />
                    <Card.Text className="font-fam font-13">
                      We recommend you to turn on 2FA for security reasons, use
                      Google Authenticator app to scan code.
                    </Card.Text>
                  </Card.Body>
                  <Button
                    appearance="primary"
                    className="centerbtn primary_color font-fam"
                  >
                    {" "}
                    Enable 2FA Security
                  </Button>
                </Col>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>

      {isMerchantWalletAddressModel && (
        <MerchantWalletAddress
          userCurrency={userCurrency}
          open={isMerchantWalletAddressModel}
          handleClose={() =>
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel)
          }
          togglePayModal={togglePayModal}
          toggleMessageModal={toggleMessageModal}
        />
      )}
      {showMessageModal ? (
        <MessageModal open={showMessageModal} onClose={toggleMessageModal} />
      ) : null}
    </>
  );
};
