import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar } from "rsuite";
import { Dropdown, Icon, Loader } from "rsuite";
import { BsFillCaretUpFill } from "react-icons/bs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BTCbuttonLogo from "../../assets/img/icons/BTCbuttonLogo.svg";
import dollar from "../../assets/img/icons/dollar.svg";
import { useSelector } from "react-redux";

import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import { BalanceData, WithdrawlData } from "../../components/Tables";
import { Col } from "@themesberg/react-bootstrap";
import WithdrawDialog from "./Components/WithdrawDialog";
import WithdrawOtpDialog from "./Components/WithdrawOtpDialog";
import { WITHDRAW, VERIFY_OTP } from "../../ApiUrl";
import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import WithdrawTransactiontable from "./WithdrawTransactiontable";
import { formatCurrency } from "../../utils/common";
import BTC from "../../assets/img/icons/BTC.svg";
import WithdrawModal from "./Components/WithdrawModal";

const cardColorClasses = [
  "light-green",
  "light-blue",
  "light-yellow",
  "light-red",
];

const Withdrawl = ({ userRole, isSingleWallet }) => {
  let token = localStorage.getItem("token");
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [userCoinBalances, setUserCoinBalances] = useState([]);
  const [userCurrency, setUserCurrency] = useState("");
  const [translist, setTranslist] = useState([]);
  const [otpModal, setOtpModal] = useState(false);
  const [title, setTitle] = useState();
  const [passPhraseModal, setPassphraseModal] = useState(false);
  const [passPhraseData, setPassPhraseData] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [withdrawDetail, setWithdrawDetail] = useState();
  const [tabValue, setTabValue] = useState("");
  const [selectedTransactionsMenu, setSelectedTransactionsMenu] =
    useState(true);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 5,
    search: "",
    type: "",
    withdrawType: "",
    coin: "",
    store: "",
    status: "",
    fromDate: "",
    toDate: "",
  });
  const [allCoins, setAllCoins] = useState(null);
  const [issuerAddress, setIssuerAddress] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      withdrawType: value,
    });
  };

  const getAllCoins = async () => {
    const response = await axios.get(`${API.GET_COINS}`, config);
    console.log("all coins", response.data.results);
    setAllCoins(response.data.results);
  };
  const getTrans = async () => {
    setloading(true);
    // let token = localStorage.getItem('token');

    const query = `?offset=${
      paginateTable.currentPage * paginateTable.pageSize
    }&limit=${paginateTable.pageSize}&search=${paginateTable.search}&coin=${
      paginateTable.coin
    }&store=${paginateTable.store}&status=${
      paginateTable.status
    }&type=withdraw`;
    merchantApi
      .get(
        `${API.TRANSACTION_LIST}${query}`,
        {
          params: {
            offset: paginateTable.currentPage * paginateTable.pageSize,
            limit: paginateTable.pageSize,
            search: paginateTable.search,
            type: "withdraw",
            coin: paginateTable.coin,
            store: paginateTable.store,
            status: paginateTable.status,
            from_date: paginateTable.fromDate,
            to_date: paginateTable.toDate,
          },
        },
        config
      )
      .then((response) => {
        if (response.data) {
          console.log(response);
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
        setloading(false);
      });
    setloading(false);
  };
  useEffect(() => {
    getTrans();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
  ]);

  useEffect(() => {
    getBalance();
    getAllCoins();
  }, []);

  const handlemodal = (text) => {
    setmodal(!modal);
    setTitle(text);
  };
  const handleOtpModal = () => {
    setOtpModal(!otpModal);
  };
  const handlePassphraseClose = () => {
    setPassphraseModal(!passPhraseModal);
  };

  const getIssuerAddress = (symbol) => {
    console.log(symbol, allCoins);
    const issuerAddressResponse = allCoins.filter(
      (coin) => coin.symbol === symbol
    )[0].issuer_address;
    console.log(issuerAddressResponse);
    setIssuerAddress(issuerAddressResponse);
  };

  const handlePassphraseSubmit = (passphraseKey) => {
    setloading(true);
    setPassPhraseData(passphraseKey);
    const receive_address = localStorage.getItem("receive_address");
    console.log("ssssssssssss", {
      from: withdrawDetail.xrpAddress,
      to: withdrawDetail.finalData.receiving_address,
      amount: withdrawDetail.finalData.amount,
      coin: withdrawDetail.coinSymbol,
    });
  };

  const handleaddStore = async (data) => {
    setloading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${WITHDRAW}${data?.method}/`,
        { ...data },
        config
      );
      setmodal(false);
      setTransactionId(response.data.data.id);
      setPassphraseModal(false);
      toast.success(response.data.message);
      setOtpModal(true);
      setloading(false);
      localStorage.removeItem("receive_address");
    } catch (error) {
      toast.error(error.response.data.message);
      setloading(false);
    }
  };

  const resendOTP = async () => {
    try {
      const response = await axios.get(
        `${API.RESEND_REFUND_OTP}${transactionId}`,
        config
      );
      toast.success(response.data.message);
      console.log(response.data.message);
    } catch (error) {
      setloading(false);
      toast.error(error.response.data.message);
    }
  };

  const handleShowPassPhrase = (childData) => {
    if (userRole === "administrator") {
      setmodal(false);
      setPassphraseModal(true);
      setWithdrawDetail(childData);
      getIssuerAddress(childData.coinSymbol);
    } else {
      const data = {
        amount: childData.finalData.amount,
        coin: childData.finalData.coin,
        type: "withdraw",
        store_id: childData.finalData.store_id,
        withdraw_address: childData.finalData.receiving_address,
        method: childData.finalData.method,
        blockchain: childData.finalData.blockchain,
        blob: "",
      };
      handleaddStore(data);
    }
  };

  const handleFinalSubmit = async (otpData) => {
    const data = {
      otp: otpData.otp,
      passphrase: passPhraseData,
    };

    setloading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${VERIFY_OTP}${transactionId}/`,
        data,
        config
      );
      toast.success(response.data.message);
      setOtpModal(!otpModal);
      getTrans();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setloading(false);
  };

  const getBalance = (coinId = null) => {
    setloading(true);
    let token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let apiUrl = `${API.GET_BALANCE}`;
    if (coinId) {
      apiUrl += `?coin=${coinId}`;
    }
    merchantApi
      .get(apiUrl, config)
      .then((response) => {
        if (response && response.data) {
          if (response.data?.data) {
            setUserCoinBalances(response.data.data.balance_in_coins);
            setUserBalance(response.data.data.total_balance);
            setUserCurrency({
              symbol: response.data.data.currency.symbol,
              decimalPlace: response.data.data.currency.decimal_place,
              symbolPrefix: response.data.data.currency.symbol_prefix,
            });
          } else {
            toast.error(response.data.message);
          }
        }
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        toast.error(
          "There was an error getting your balance information. Please try again!"
        );
      });
  };

  console.log(userCoinBalances);
  console.log(allCoins);

  return (
    <>
      {/* {loading && <Loader backdrop content="loading..." vertical />} */}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="page-header space-between w-100">
                <div>
                  <p className="report-title">Total Balance</p>
                  <h3 className="m-0 page-title">
                    {formatCurrency(
                      userBalance,
                      userCurrency.decimalPlace,
                      userCurrency.symbol,
                      userCurrency.symbolPrefix
                    )}
                  </h3>
                </div>
                {(userRole === "franchisee" && !isSingleWallet) ||
                (userRole === "manager" && !isSingleWallet) ||
                (userRole === "administrator" && isSingleWallet) ? (
                  <div className="gap-4 tools">
                    <Button
                      onClick={() => handlemodal("Withdraw In Crypto")}
                      appearance="default"
                      style={{
                        minHeight: "40px",
                        height: "40px",
                        justifyContent: "center",
                      }}
                      className="btn load text-white d-flex gap-2 align-items-center filter"
                    >
                      <img src={BTCbuttonLogo} alt="" />
                      {""} Withdraw
                    </Button>
                    {/* <Button
                      disabled={true}
                      onClick={() => handlemodal('Withdraw USD')}
                      appearance="subtle"
                      style={{
                        minHeight: '40px',
                        height: '40px',
                        justifyContent: 'center',
                      }}
                      className="btn white-btn d-flex gap-2 align-items-center filter"
                    >
                      <img src={dollar} alt="" />
                      Withdraw {userCurrency.symbol}
                    </Button> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="balance-cards d-flex align-items-center space-between w-100 gap-4">
                {allCoins
                  ? userCoinBalances.map((coin, index) => {
                      const currentCrypto = allCoins.filter(
                        (crypto) => crypto.symbol === coin.coin
                      )[0];
                      console.log(currentCrypto);
                      return (
                        <div
                          className={`trans-card ${
                            cardColorClasses[index % 4]
                          }`}
                        >
                          <div className="card-content">
                            <img
                              src={currentCrypto?.logo}
                              style={{
                                width: "10%",
                                background: "transparent",
                              }}
                              alt=""
                              srcset=""
                            />
                            <p>{coin.coin}</p>
                            {/* <span>
                              {coin.value} {coin.coin}
                            </span>
                            <br /> */}
                            <span>
                              {formatCurrency(
                                coin?.value_in_currency,
                                userCurrency?.decimalPlace,
                                userCurrency?.symbol,
                                userCurrency?.symbolPrefix
                              )}
                            </span>
                            <p>
                              1 {coin.coin} ={" "}
                              {formatCurrency(
                                currentCrypto?.rate,
                                userCurrency?.decimalPlace,
                                userCurrency?.symbol,
                                userCurrency?.symbolPrefix
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="w-100">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0b0523",
                    },
                  }}
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab
                    disabled={true}
                    value=""
                    style={{ color: `${tabValue === "" ? "#0b0523" : ""}` }}
                    label="All"
                  />
                  <Tab
                    disabled={true}
                    value="crypto"
                    style={{
                      color: `${tabValue === "crypto" ? "#0b0523" : ""}`,
                    }}
                    label="Crypto"
                  />
                  <Tab
                    disabled={true}
                    value="bankWithdraw"
                    style={{
                      color: `${tabValue === "bankWithdraw" ? "#0b0523" : ""}`,
                    }}
                    label="Bank Withdraw"
                  />
                </Tabs>
              </div>
            </div>
            <div className="mt-4">
              <Col xs={12} xl={12} className="mb-4">
                <WithdrawTransactiontable
                  translist={translist}
                  loading={loading}
                  setpaginateTable={setpaginateTable}
                  paginateTable={paginateTable}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
      {/* {modal && (
        <WithdrawDialog
          loading={loading}
          open={modal}
          onClose={handlemodal}
          handleShowPassPhrase={handleShowPassPhrase}
          userCurrency={userCurrency}
          // error={addStoreError}
          title={title}
          isSingleWallet={isSingleWallet}
        />
      )} */}
      {modal && (
        <WithdrawModal
          userRole={userRole}
          loading={loading}
          isOpen={modal}
          handleCloseModal={() => {
            handlemodal();
            getTrans();
          }}
          handleShowPassPhrase={handleShowPassPhrase}
          userCurrency={userCurrency}
          // error={addStoreError}
          title={title}
          isSingleWallet={isSingleWallet}
        />
      )}
    </>
  );
};

function mapStateToProps({ authReducer }) {
  const { userRole, isSingleWallet } = authReducer;
  return {
    userRole,
    isSingleWallet,
  };
}

export default connect(mapStateToProps, null)(Withdrawl);

// if(coin?.length !== 3) {
//   coin =  Buffer.from(coin, 'utf-8').toString('hex');
//   coin = coin.padEnd(40, "0");
// }
