import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Table, Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';

import { merchantApi } from '../../redux/api/apiConfig';
import { TRANSACTION_LIST } from '../../ApiUrl';
import { formatCurrency } from '../../utils/common';

const TodayTransaction = () => {
  const history = useHistory();
  const [recentTransaction, setRecentTransaction] = useState([]);

  useEffect(() => {
    getTodayTransaction();
  }, []);

  const getTodayTransaction = () => {
    merchantApi
      .get(TRANSACTION_LIST, {
        params: {
          limit: 5,
          offset: 0,
        },
      })
      .then((response) => {
        const { data } = response;
        console.log('data.results', data.results);
        setRecentTransaction(data.results);
      })
      .catch((error) => {});
  };

  const TableRow = (props) => {
    const {
      reference_number,
      sender,
      receiver,
      value,
      coin,
      type,
      created_date,
      status,
    } = props;
    const statusList = {
      processed: { color: 'success', label: 'Completed' },
      pending: { color: 'warning', label: 'Pending' },
      failed: { color: 'danger', label: 'Failed' },
    };

    console.log(coin);
    const statusColor = statusList[status.value]
        ? statusList[status.value].color
        : 'danger',
      statusLabel = statusList[status.value]
        ? statusList[status.value].label
        : 'Loading...';

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {reference_number}
        </td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {receiver?.name}
        </td>
        <td className="txt-oflo  font-12 text-center font-fam">
          {sender?.name}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {receiver?.name}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {formatCurrency(value, coin.decimal_place, coin.symbol)}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {coin.symbol}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {type}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {moment().format('YY') === moment(created_date).format('YY')
            ? moment(created_date).format('MMM Do')
            : moment(created_date).format('MMM Do YY')}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
          <span className={`text-${statusColor}`}>● {statusLabel}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Recent Transaction</h5>
          </Col>
          <Col className="text-end">
            <Button
              onClick={() => {
                history.push('/reports/transaction');
              }}
              variant="secondary"
              size="sm"
            >
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="font-11">
              TRANSACTION ID
            </th>
            <th scope="col" className="font-11">
              MERCHANT ID
            </th>
            <th scope="col" className="font-11">
              FORM (VA)
            </th>
            <th scope="col" className="font-11">
              TO (POS)
            </th>
            <th scope="col" className="font-11">
              AMOUNT
            </th>
            <th scope="col" className="font-11">
              CUR
            </th>
            <th scope="col" className="font-11">
              TYPE
            </th>
            <th scope="col" className="font-11">
              DATE
            </th>
            <th scope="col" className="font-11">
              STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          {recentTransaction.length > 0 &&
            recentTransaction.map((pv) => (
              <TableRow key={`report-list-${pv.id}`} {...pv} />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default TodayTransaction;
