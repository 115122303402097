import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Dropdown } from "@themesberg/react-bootstrap";
import { GeneralInfo } from "../GeneralInfo";
import ProfileCard from "./ProfileCard";
import { merchantApi } from "../../redux/api/apiConfig";
import { MERCHANT_PROFILE } from "../../ApiUrl";
import { toast } from "react-toastify";
import { Divider, Stack, Typography } from "@mui/material";
import facebookIcon from "../../assets/img/socialFacebook.svg";
import xIcon from "../../assets/img/socialX.svg";
import instagramIcon from "../../assets/img/socialInstagram.svg";
import linkedInIcon from "../../assets/img/socialLinkedin.svg";
import { LinkedIn } from "@material-ui/icons";

export default () => {
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const [userProfileData, setUserProfileData] = useState({});

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    merchantApi
      .get(`${MERCHANT_PROFILE}${userInfo.user_id}/`)
      .then((response) => {
        const { data } = response;
        setUserProfileData(data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
      });
  };

  return (
    <>
      {userProfileData && Object.keys(userProfileData).length > 0 && (
        <>
          <Col xs={12} xl={12}>
            <Row style={{ maxWidth: "100%" }}>
              <Col xs={12}>
                <ProfileCard userProfileData={userProfileData} />
              </Col>
            </Row>
          </Col>
          <Row style={{ maxWidth: "100%", margin: "0" }} className="mt-3">
            <Col xs={12} xl={12}>
              <GeneralInfo userProfileData={userProfileData} />
            </Col>
          </Row>
          <Divider> CONNECT WITH US</Divider>
          <Stack
            sx={{ justifyContent: "space-around", flexDirection: "row", my: 5 }}
          >
            <a
              href="https://www.facebook.com/spendthebits/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookIcon}
                alt="facebook"
                style={{ width: "5rem" }}
              />
            </a>
            <a
              href="https://www.instagram.com/spendthebits/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramIcon}
                alt="instagram"
                style={{ width: "5rem" }}
              />
            </a>
            <a
              href="https://x.com/Spend_The_Bits"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xIcon} alt="X" style={{ width: "5rem" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/spend-the-bits"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedInIcon}
                alt="linkedin"
                style={{ width: "5rem" }}
              />
            </a>
          </Stack>
        </>
      )}
    </>
  );
};
