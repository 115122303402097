import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import KybMenu from "./KybMenu";
import KybForm from "./KybForm";
import { toast } from "react-toastify";
import startKyc from "../../../../assets/img/startKyc1.png";
import kycpending from "../../../../assets/img/kybpending.svg";
import kybsubmitted from "../../../../assets/img/kybsubmitted.svg";
import kybcomplete from "../../../../assets/img/kybComplete1.png";
import { Stack, Skeleton } from "@mui/material";
import { Button } from "rsuite";
import {
  getbusinesscategory,
  getbusiesstype,
} from "../../../../redux/actions/businessApi";
import { connect } from "react-redux";

import { ADD_MERCHANT_API, INITIATE_KYB } from "../../../../ApiUrl";
import { merchantApi } from "../../../../redux/api/apiConfig";
import {
  getPersonalInfo,
  getStatus,
} from "../../../../redux/actions/kybActions";
import _ from "lodash";
import ButtonPrimary from "../../../../components/ButtonPrimary";

const imageStyle = {
  width: "40%",
  maxWidth: "400px",
  minWidth: "300px",
};

const KYB = ({ getbusinesscategory, getbusiesstype, getStatus }) => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState("welcome");
  const [loading, setLoading] = useState(true);
  const [initiateLoading, setInitiateLoading] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [kycStatus, setKycStatus] = useState("not_done");
  const kybData = useSelector((state) => state.kybReducer);
  const statusText = kybData?.status?.data?.status_text;
  // console.log("KYB DATA", kybData);
  let { kyb_status } = merchantData;

  let user = JSON.parse(localStorage.getItem("user-info"));

  const currentuser = useSelector((state) => state.profileReducer.user);

  useEffect(() => {
    dispatch(
      getPersonalInfo(
        currentuser?.shareholder?.id,
        (res) => {
          setKycStatus(res?.kyc);
        },
        (err) => console.log("xwex23x23x", err)
      )
    );
  }, []);

  useEffect(() => {
    getbusiesstype();
    getbusinesscategory();
    getStatus();
    getMerchantDetail();
  }, []);

  const initiateKybHandler = () => {
    setInitiateLoading(true);
    merchantApi
      .post(INITIATE_KYB)
      .then((response) => {
        setInitiateLoading(false);
        toast.success("KYB Successfully Initiated");
        setStage("verify");
      })
      .catch((error) => {
        setInitiateLoading(false);
        toast.error("KYB Initiation Failed");
      });
  };

  let content = "";
  let displayText = "";
  let statusColor = "";

  if (kycStatus === "complete" && kyb_status === "complete") {
    displayText = "Complete";
    statusColor = "complete";
    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: "1",
          textAlign: "center",
          alignItems: "center",
          padding: "0 2rem",
          marginTop: 8,
        }}
      >
        <img style={imageStyle} src={kybcomplete} alt="completed" srcSet="" />
        <h4>Verification Completed</h4>
      </Stack>
    );
  } else if (kycStatus === "not_done" && kyb_status === "not_done") {
    displayText = "Pending";
    statusColor = "pending";

    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: "1",
          textAlign: "center",
          alignItems: "center",
          padding: { xs: "0", sm: "0 2rem" },
          marginTop: 8,
        }}
      >
        <h5>Please verify your merchant account to get started </h5>
        <img style={{ ...imageStyle }} src={startKyc} alt="pending" srcSet="" />
        <ul style={{ marginBottom: "1rem" }}>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            To aid the government in combating the financing of terrorism and
            money laundering activities, federal law mandates that all financial
            institutions acquire, verify, and document information identifying
            each legal entity and individual opening an account.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            For your understanding: Upon opening an account, we will request
            details such as your name, address, date of birth, and relevant
            documentation pertaining to the legal entity for which the account
            is being opened.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            This includes information about its control persons and beneficial
            owners, facilitating our ability to identify both you and the legal
            entity you represent.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            Additionally, we may require identification documentation for you,
            the legal entity, and any affiliated entities or individuals
            associated with the legal entity for which the account is being
            established.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            The account verification process typically requires approximately
            1-3 working days.
          </li>
        </ul>
        <ButtonPrimary onClick={initiateKybHandler} loading={initiateLoading}>
          Start KYB
        </ButtonPrimary>
      </Stack>
    );
  } else if (kyb_status === "application_inprogress") {
    displayText = "Pending";
    statusColor = "pending";

    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: "1",
          textAlign: "center",
          alignItems: "center",
          padding: "0 2rem",
          marginTop: 8,
        }}
      >
        <h5>Please verify your merchant account to get started </h5>
        <img style={{ ...imageStyle }} src={startKyc} alt="pending" srcSet="" />
        <ul style={{ marginBottom: "1rem" }}>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            To aid the government in combating the financing of terrorism and
            money laundering activities, federal law mandates that all financial
            institutions acquire, verify, and document information identifying
            each legal entity and individual opening an account.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            For your understanding: Upon opening an account, we will request
            details such as your name, address, date of birth, and relevant
            documentation pertaining to the legal entity for which the account
            is being opened.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            This includes information about its control persons and beneficial
            owners, facilitating our ability to identify both you and the legal
            entity you represent.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            Additionally, we may require identification documentation for you,
            the legal entity, and any affiliated entities or individuals
            associated with the legal entity for which the account is being
            established.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            The account verification process typically requires approximately
            1-3 working days.
          </li>
        </ul>
        <ButtonPrimary onClick={() => setStage("verify")}>
          Continue KYB
        </ButtonPrimary>
      </Stack>
    );
  } else if (kyb_status === "rejected") {
    displayText = "Failed";
    statusColor = "rejected";
    content = (
      <>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            flexGrow: "1",
            textAlign: "center",
            alignItems: "center",
            padding: "0 2rem",
          }}
        >
          <h5>Account Verification Failed</h5>
          <img
            style={{ ...imageStyle, marginBottom: "2rem" }}
            src={kycpending}
            alt="submitted"
            srcSet=""
          />
          <p className="kyb-info">
            Your KYB is failed, please click the button below to review and
            update the necessary information to get your business started.
          </p>

          {merchantData?.kyb_error_suggestions?.suggestions.length > 0 &&
            merchantData?.kyb_error_suggestions?.suggestions.map(
              (suggestion) =>
                suggestion && (
                  <li
                    className="kyb-info"
                    style={{ textAlign: "left", width: "70%" }}
                  >
                    {suggestion}
                  </li>
                )
            )}

          <Button
            onClick={() => setStage("verify")}
            style={{
              minHeight: "40px",
              maxWidth: "fit-content",
              maxHeight: "40px",
            }}
            appearance="default"
            className="btn load text-white d-flex flex-row gap-2"
          >
            Review and Update
          </Button>
        </Stack>
      </>
    );
  } else if (kycStatus === "rejected") {
    displayText = "Failed";
    statusColor = "rejected";
    content = (
      <>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            flexGrow: "1",
            textAlign: "center",
            alignItems: "center",
            padding: "0 2rem",
          }}
        >
          <h5>Account Verification Failed</h5>
          <img
            style={{ ...imageStyle, marginBottom: "2rem" }}
            src={kycpending}
            alt="submitted"
            srcSet=""
          />
          <p className="kyb-info" style={{ paddingBottom: "20px" }}>
            Your KYC is failed, please click the button below to review and
            update the necessary information to get your business started.
          </p>

          {merchantData?.kyc_error_suggestions?.suggestions.length > 0 &&
            merchantData?.kyc_error_suggestions?.suggestions.map(
              (suggestion) =>
                suggestion && (
                  <li
                    className="kyb-info"
                    style={{ textAlign: "left", width: "70%" }}
                  >
                    {suggestion}
                  </li>
                )
            )}

          <Button
            onClick={() => setStage("verify")}
            style={{
              minHeight: "40px",
              maxWidth: "fit-content",
              maxHeight: "40px",
            }}
            appearance="default"
            className="btn load text-white d-flex flex-row gap-2"
          >
            Review and Update
          </Button>
        </Stack>
      </>
    );
  } else if (
    kycStatus === "awaiting_approval" ||
    kyb_status === "awaiting_approval"
  ) {
    displayText = "In Process";
    statusColor = "application submitted";
    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: "1",
          textAlign: "center",
          alignItems: "center",
          padding: "0 2rem",
          marginTop: 8,
        }}
      >
        <h5>Account Verification in Process</h5>
        <img
          style={{ ...imageStyle, marginBottom: "2rem" }}
          src={kybsubmitted}
          alt="submitted"
          srcSet=""
        />
        <p className="kyb-info">
          The account verification process typically requires approximately 1-3
          working days.
        </p>
      </Stack>
    );
  } else {
    displayText = "Pending";
    statusColor = "pending";

    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: "1",
          textAlign: "center",
          alignItems: "center",
          padding: "0 2rem",
          marginTop: 8,
        }}
      >
        <h5>Please verify your merchant account to get started </h5>
        <img
          style={{ ...imageStyle, width: "800px", maxWidth: "800px" }}
          src={startKyc}
          alt="pending"
          srcSet=""
        />
        <ul>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            To aid the government in combating the financing of terrorism and
            money laundering activities, federal law mandates that all financial
            institutions acquire, verify, and document information identifying
            each legal entity and individual opening an account.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            For your understanding: Upon opening an account, we will request
            details such as your name, address, date of birth, and relevant
            documentation pertaining to the legal entity for which the account
            is being opened.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            This includes information about its control persons and beneficial
            owners, facilitating our ability to identify both you and the legal
            entity you represent.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            Additionally, we may require identification documentation for you,
            the legal entity, and any affiliated entities or individuals
            associated with the legal entity for which the account is being
            established.
          </li>
          <li className="kyb-info" style={{ textAlign: "left" }}>
            The account verification process typically requires approximately
            1-3 working days.
          </li>
        </ul>
        <Button
          onClick={() => setStage("verify")}
          style={{
            minHeight: "40px",
            maxWidth: "fit-content",
            maxHeight: "40px",
          }}
          appearance="default"
          className="btn load text-white d-flex flex-row gap-2"
        >
          Start KYB
        </Button>
      </Stack>
    );
  }

  const getMerchantDetail = () => {
    setLoading(true);
    merchantApi
      .get(ADD_MERCHANT_API)
      .then((response) => {
        setLoading(false);
        setMerchantData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  return (
    <>
      {" "}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="tabing stb-h-100">
          <div className="reports bg-white p-xl-4 p-3 radi stb-h-100">
            {loading ? (
              <Skeleton animation={"wave"} variant="rounded" height={1000} />
            ) : (
              <>
                <div className="d-flex align-items-center pb-4 space-between">
                  <div>
                    <h3 className="m-0 page-title">
                      Welcome {_.capitalize(user.first_name)}!
                    </h3>

                    {(kycStatus === "rejected" ||
                      kyb_status === "rejected") && (
                      <p>Complete the following steps to finish your setup.</p>
                    )}
                  </div>
                </div>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                  className="kyb-container"
                >
                  {stage === "welcome" && statusText === "Failed" ? (
                    <KybMenu
                      displayText={displayText}
                      statusColor={statusColor}
                    />
                  ) : (
                    ""
                  )}
                  {stage === "welcome" ? (
                    <>{content}</>
                  ) : !loading ? (
                    <KybForm
                      setStage={setStage}
                      isKycRejected={kycStatus === "rejected"}
                      isKybRejected={kyb_status === "rejected"}
                      currentStep={parseInt(
                        kybData?.status?.data?.current_step
                      )}
                    />
                  ) : (
                    ""
                  )}
                </Stack>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    data: state.kybReducer.data,
    category: state.businessReducer.category,
    businesstype: state.businessReducer.businessType,
  };
}

const actionCreators = {
  getbusiesstype,
  getbusinesscategory,
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(KYB);
