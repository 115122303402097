import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
//
import { Box } from "@mui/material";
import ChatMessageItem from "./ChatMessageItem";
import { useSelector } from "react-redux";
import ChatMessageItemSkeleton from "./ChatMessageItemSkeleton";
// import Lightbox from '../../../components/lightbox';
const mime = require("mime-types");

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
};

export default function ChatMessageList({ conversation, getRootProps }) {
  const scrollRef = useRef(null);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const { isLoading, activeConversationId } = useSelector(
    (state) => state.chat
  );
  // const isLoading = true;

  const { replies } = conversation || {};

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [replies]);

  const imagesLightbox = conversation?.replies
    .filter(
      (reply) =>
        reply?.attachments.length > 0 &&
        mime.lookup(reply?.attachments?.[0]?.attachment).split("/")[0] ===
          "image"
    )
    .map((reply) => ({ src: reply?.attachments?.[0]?.attachment }));
  console.log(imagesLightbox);

  const handleOpenLightbox = (imageUrl) => {
    console.log(imageUrl);
    const imageIndex = imagesLightbox.findIndex(
      (image) => image.src === imageUrl
    );
    setSelectedImage(imageIndex);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  const loading = isLoading || (conversation.id === "" && activeConversationId);

  return (
    <>
      {/* <Scrollbar
        scrollableNodeProps={{ ref: scrollRef }}
        sx={{ p: 3, height: 1 }}
      > */}
      <Box
        {...getRootProps()}
        sx={{ overflowX: "auto", p: 3, height: 1 }}
        ref={scrollRef}
      >
        {loading
          ? [...Array(12)].map((item, i) => (
              <ChatMessageItemSkeleton sx={{ ml: i % 3 === 0 ? "auto" : "" }} />
            ))
          : replies.length > 0 &&
            replies.map((reply) => (
              <ChatMessageItem
                key={reply.id}
                message={reply}
                conversation={conversation}
                onOpenLightbox={handleOpenLightbox}
              />
            ))}
      </Box>
      {/* </Scrollbar> */}

      {/* <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      /> */}
    </>
  );
}
