// Utility: Convert text to ArrayBuffer

function textToArrayBuffer(text) {
  return new TextEncoder().encode(text);
}

// Utility: Convert ArrayBuffer to text

function arrayBufferToText(buffer) {
  return new TextDecoder().decode(buffer);
}

// Derive a cryptographic key from the password

export const DeriveKey = async (password, salt) => {
  const passwordKey = await window.crypto.subtle.importKey(
    "raw",

    textToArrayBuffer(password),

    "PBKDF2",

    false,

    ["deriveKey"]
  );

  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",

      salt: salt,

      iterations: 100000,

      hash: "SHA-256",
    },

    passwordKey,

    { name: "AES-GCM", length: 256 },

    false,

    ["encrypt", "decrypt"]
  );
};

// Encrypt data with a password and store it in local storage

export const EncryptAndStore = async (key, plaintext, storageKey) => {
  const salt = window.crypto.getRandomValues(new Uint8Array(16));

  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

  const derivedKey = await DeriveKey(key, salt);

  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",

      iv: iv,
    },

    derivedKey,

    textToArrayBuffer(plaintext)
  );

  const dataToStore = {
    salt: Array.from(new Uint8Array(salt)),

    iv: Array.from(new Uint8Array(iv)),

    ciphertext: Array.from(new Uint8Array(encrypted)),
  };

  localStorage.setItem(storageKey, JSON.stringify(dataToStore));

  console.log("Data encrypted and stored in localStorage.");
};

// Decrypt data from local storage with a password

export const DecryptFromStorage = async (key, storageKey) => {
  console.log(storageKey, key);

  const storedData = localStorage.getItem(storageKey);

  if (!storedData) {
    throw new Error("No data found in localStorage.");
  }

  const { salt, iv, ciphertext } = JSON.parse(storedData);

  const derivedKey = await DeriveKey(key, new Uint8Array(salt));

  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",

      iv: new Uint8Array(iv),
    },

    derivedKey,

    new Uint8Array(ciphertext)
  );

  return arrayBufferToText(decrypted);
};

// Example usage:

// (async () => {
//   const password = "securepassword123";

//   const plaintext = "Sensitive data to encrypt";

//   const storageKey = "encrypted_data";

//   // Encrypt and store data

//   await encryptAndStore(password, plaintext, storageKey);

//   // Retrieve and decrypt data

//   const decryptedData = await decryptFromStorage(password, storageKey);

//   console.log("Decrypted data:", decryptedData);
// })();
